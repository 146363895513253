import React, {useState} from 'react'
import { DataGrid} from '@mui/x-data-grid'
import {Box, Stack} from '@mui/material'
import AddIcon from '@mui/icons-material/EmojiNature'
import {MyButtonBacan2} from 'src/components/MyButtonBacan2'
import RobotoCondensedRegular from "../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import {f} from "../commons";

export const MyTableRecursos2 = ({
                                   id,
                                   formValues,
                                   setFormValues,
                                   setRowSelected,
                                   canEdit=false,
                                   addItem=null,
                                   showHolotipo=false,
                                   mode='',
                                })  => {
  const rows = formValues[id]

  const processRowUpdateATM = (newRow) => {
    let myNewRow = {...newRow}
    myNewRow.cantidadSolicitada = Math.round(Number(myNewRow.cantidadSolicitada))
    if(myNewRow.cantidadSolicitada > myNewRow.cantidadAutorizada) {
      myNewRow.cantidadSolicitada = myNewRow.cantidadAutorizada
    }
    if(myNewRow.cantidadSolicitada < 0) {
      myNewRow.cantidadSolicitada = 0
    }

    const nId = myNewRow.id
    let nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...myNewRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return myNewRow
  }

  const processRowUpdateWF08 = (newRow) => {
    let myNewRow = {...newRow}
    myNewRow.importacionSolicitada = Math.round(Number(myNewRow.importacionSolicitada))

    if(myNewRow.cantidadSolicitada < myNewRow.importacionSolicitada) {
      myNewRow.importacionSolicitada = myNewRow.cantidadSolicitada
    }
    if(myNewRow.importacionSolicitada < 0) {
      myNewRow.importacionSolicitada = 0
    }
    const nId = myNewRow.id
    let nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...myNewRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return myNewRow
  }

  const processRowUpdateWF09 = (newRow) => {
    let myNewRow = {...newRow}
    myNewRow.holotiposSolicitados = Math.round(Number(myNewRow.holotiposSolicitados))

    if(myNewRow.saldoHolotipos < myNewRow.holotiposSolicitados) {
      myNewRow.holotiposSolicitados = myNewRow.saldoHolotipos
    }
    if(myNewRow.holotiposSolicitados < 0) {
      myNewRow.holotiposSolicitados = 0
    }
    const nId = myNewRow.id
    let nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...myNewRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return myNewRow
  }

  const [selectionModel, setSelectionModel] = useState([])
  const cols = {
    'WF08':    columnsWF08(canEdit),
    'ATM-IS':  columnsATM_IS(canEdit),
    'ATM-ES':  columnsATM_ES(canEdit, showHolotipo),
    'ATM-ES2': columnsATM_ES2(canEdit, showHolotipo),
    'WF09':    columnsWF09(canEdit, showHolotipo),        // forw WF09
  }[mode] ?? columns(canEdit)

  const func = {
    'WF08':     processRowUpdateWF08,
    'WF09':     processRowUpdateWF09
}[mode] ?? processRowUpdateATM
  return (
    <Box sx={{ pb: '24px', width: '100%',}}>
      <Stack direction="column" spacing={1}>
        {canEdit &&
          <Stack direction='row'
                 spacing={3}
                 justifyContent='flex-end'
                 alignItems='center'>
            {mode === '' &&
              <Stack direction='row'
                     spacing={3}>
                <MyButtonBacan2 onClick={addItem}
                                icon={AddIcon}
                                label={'Agregar recurso'}/>
              </Stack>
            }
          </Stack>
        }
        <DataGrid experimentalFeatures={{ newEditingApi: true }}
                  processRowUpdate={func}
                  rows={rows}
                  // columns={mode==='WF08'?columnsWF08(canEdit):columns(canEdit)}
                  columns={cols}
                  autoHeight={true}
                  rowHeight={32}
                  pageSize={8}
                  rowsPerPageOptions={[8]}
                  selectionModel={selectionModel}
                  getRowHeight={() => 'auto'}
                  sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: "rgba(244,244,244,0.4)",
                      color: "#aeaeae",
                      fontSize: '0.8rem',
                      fontfamily: RobotoCondensedRegular,
                      fontweight: 'lighter',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      '& .MuiDataGrid-row': {
                        backgroundColor: 'rgba(255, 255, 255, .7)',
                        fontSize: '0.8rem',
                        fontfamily: RobotoCondensedRegular,
                        fontweight: 'lighter',
                        color: "#888888",
                      },
                      '& .Mui-selected': {
                        color: "#000000",
                      }
                    },
                  }}
                  onCellClick={(e) => {
                    if(f.isValid(setRowSelected)) {
                      setRowSelected(e.row.mid)
                    }
                  }} />
      </Stack>
    </Box>
  )
}

const columns = (canEdit) => [
  {
    field:        'id',
    headerName:   'ID',
    type:         'number',
    editable:     false,
  },
  {
    field:        'mid',
    headerName:   'mid',
    editable:     false,
    hide:         true,
  },
  {
    field:        'origen',
    headerName:   'Origen',
    editable:     false,
  },
  {
    field:        'scientificname',
    headerName:   'Nombre científico',
    editable:     false,
    width:        160,
  },
  {
    field:        'holotipo',
    headerName:   'Holotipo',
    type:         'boolean',
    editable:     canEdit && true,
    width:        120,
  },
  {
    field:        'cites',
    headerName:   'Cites',
    editable:     canEdit && true,
    width:        120,
  },
  {
    field:        'tipo',
    headerName:   'Tipo de recolección',
    editable:     canEdit && true,
    width:        120,
  },
  {
    field:        'metodo',
    headerName:   'Metodo',
    editable:     canEdit && true,
    width:        120,
  },
  {
    field:        'tratamiento',
    headerName:   'Tratamiento',
    editable:     canEdit && true,
    width:        120,
  },
  {
    field:        'saldorATM',
    headerName:   'Cantidad autorizada',
    editable:     false,
    type:         'number',
    width:        120,
  },
  {
    field:        'cantidadSolicitada',
    headerName:   'Cantidad Solicitada',
    type:         'number',
    editable:     canEdit && true,
    width:        120,
  },
]

const columnsWF08 = (canEdit) => [
  {
    field:        'id',
    headerName:   'ID',
    type:         'number',
    editable:     false,
    hide:         true,
  },
  {
    field:        'mid',
    headerName:   'mid',
    editable:     false,
    hide:         true,
  },
  {
    field:        'origen',
    headerName:   'Origen',
    editable:     false,
    hide:         true,
  },
  {
    field:        'taxonrank',
    headerName:   'Rango',
    editable:     false,
    width:        160,
  },
  {
    field:        'scientificname',
    headerName:   'Nombre científico',
    editable:     false,
    width:        160,
  },
  {
    field:        'holotipo',
    headerName:   'Holotipo',
    type:         'boolean',
    editable:     false,
    width:        120,
  },
  {
    field:        'cites',
    headerName:   'Cites',
    editable:     false,
    width:        120,
  },
  {
    field:        'tipo',
    headerName:   'Tipo',
    editable:     false,
    width:        120,
  },
  {
    field:        'metodo',
    headerName:   'Metodo',
    editable:     canEdit,
    width:        120,
  },
  {
    field:        'tratamiento',
    headerName:   'Tratamiento',
    editable:     canEdit,
    width:        120,
  },
  {
    field:        'cantidadSolicitada',
    headerName:   'Cantidad declarada ATM',
    type:         'number',
    editable:     false,
    width:        180,
    hide:         true,
  },
  {
    field:        'saldoImportacion',
    headerName:   'Cantidad autorizada',
    editable:     false,
    type:         'number',
    width:        120,
    // hide:         true,
  },
  {
    field:        'importacionSolicitada',
    headerName:   'Cantidad Solicitada',
    type:         'number',
    editable:     canEdit,
    width:        120,
  },
  {
    field:        'centroDocumentacion',
    headerName:   'Centro Documentación',
    type:         'number',
    headerAlign:  'left',
    editable:     false,
    width:        320,
  },
]

const columnsATM_IS = (canEdit) => [
  {
    field:        'id',
    headerName:   'ID',
    type:         'number',
    editable:     false,
    hide:         true,
  },
  {
    field:        'mid',
    headerName:   'mid',
    editable:     false,
    hide:         true,
  },
  {
    field:        'origen',
    headerName:   'Origen',
    editable:     false,
    hide:         true,
  },
  {
    field:        'taxonrank',
    headerName:   'Rango',
    editable:     false,
    width:        120,
  },
  {
    field:        'scientificname',
    headerName:   'Nombre científico',
    editable:     false,
    width:        240,
  },
  {
    field:        'tipo',
    headerName:   'Muestra/lote',
    editable:     false,
  },
  {
    field:        'submuestra',
    headerName:   'Submuetra',
    editable:     false,
  },
  {
    field:        'otraSubmuestra',
    headerName:   'Otra submuestra',
    editable:     false,
  },
  {
    field:        'descripcion',
    headerName:   'Descripción',
    width:        200,
    editable:     false,
  },
  {
    field:        'cantidadAutorizada',
    headerName:   'Cantidad autorizada',
    editable:     false,
    type:         'number',
    width:        120,
  },
  {
    field:        'cantidadSolicitada',
    headerName:   'Cantidad Declarada',
    type:         'number',
    editable:     canEdit && true,
    width:        120,
  },
  {
    field:        'cites',
    headerName:   'Cites',
    editable:     false,
    width:        120,
  },
  {
    field:        'listaRoja',
    headerName:   'Lista roja',
    editable:     false,
    width:        120,
  },
]

const columnsATM_ES = (canEdit, showHolotipo) => [
  {
    field:        'id',
    headerName:   'ID',
    type:         'number',
    editable:     false,
    hide:         true,
  },
  {
    field:        'mid',
    headerName:   'mid',
    editable:     false,
    hide:         true,
  },
  {
    field:        'origen',
    headerName:   'Origen',
    editable:     false,
    hide:         true,
  },
  {
    field:        'taxonrank',
    headerName:   'Rango',
    editable:     false,
  },
  {
    field:        'scientificname',
    headerName:   'Nombre científico',
    editable:     false,
    width:        300,
  },
  {
    field:        'holotipo',
    headerName:   '¿holotipo?',
    type:         'boolean',
    editable:     true,
    width:        120,
    hide:         !showHolotipo,
  },
  {
    field:        'tipo',
    headerName:   'Muestra/lote',
    editable:     false,
    hide:         true,
  },
  {
    field:        'submuestra',
    headerName:   'Submuetra',
    editable:     false,
    hide:         true,
  },
  {
    field:        'otraSubmuestra',
    headerName:   'Otra submuestra',
    editable:     false,
    hide:         true,
  },
  {
    field:        'cantidadAutorizada',
    headerName:   'Cantidad autorizada',
    editable:     false,
    type:         'number',
    width:        180,
  },
  {
    field:        'cantidadSolicitada',
    headerName:   'Cantidad Declarada',
    type:         'number',
    editable:     canEdit && true,
    width:        180,
  },
  {
    field:        'centroDocumentacion',
    headerName:   'Centro Documentación',
    type:         'number',
    headerAlign:  'left',
    editable:     canEdit && true,
    width:        320,
  },
]

const columnsATM_ES2 = (canEdit, showHolotipo) => [
  {
    field:        'id',
    headerName:   'ID',
    type:         'number',
    editable:     false,
    hide:         true,
  },
  {
    field:        'mid',
    headerName:   'mid',
    editable:     false,
    hide:         true,
  },
  {
    field:        'origen',
    headerName:   'Origen',
    editable:     false,
    hide:         true,
  },
  {
    field:        'taxonrank',
    headerName:   'Rango',
    editable:     false,
  },
  {
    field:        'scientificname',
    headerName:   'Nombre científico',
    editable:     false,
    width:        300,
  },

  {
    field:        'metodo',
    headerName:   'Método',
    editable:     canEdit,
    width:        300,
  },

  {
    field:        'tratamiento',
    headerName:   'Tratamiento',
    editable:     canEdit,
    width:        300,
  },

  {
    field:        'holotipo',
    headerName:   '¿holotipo?',
    type:         'boolean',
    editable:     canEdit,
    width:        120,
    hide:         !showHolotipo,
  },
  {
    field:        'tipo',
    headerName:   'Muestra/lote',
    editable:     false,
    hide:         true,
  },
  {
    field:        'submuestra',
    headerName:   'Submuetra',
    editable:     false,
    hide:         true,
  },
  {
    field:        'otraSubmuestra',
    headerName:   'Otra submuestra',
    editable:     false,
    hide:         true,
  },
  // {
  //   field:        'cantidadAutorizada',
  //   headerName:   'Cantidad autorizada',
  //   editable:     false,
  //   type:         'number',
  //   width:        180,
  // },
  // {
  //   field:        'cantidadSolicitada',
  //   headerName:   'Cantidad Declarada',
  //   type:         'number',
  //   editable:     canEdit && true,
  //   width:        180,
  // },
  {
    field:        'saldoImportacion',
    headerName:   'Cantidad autorizada',
    editable:     false,
    type:         'number',
    width:        120,
    // hide:         true,
  },
  {
    field:        'importacionSolicitada',
    headerName:   'Importación Solicitada',
    type:         'number',
    editable:     false,
    width:        120,
  },

  {
    field:        'centroDocumentacion',
    headerName:   'Centro Documentación',
    type:         'number',
    headerAlign:  'left',
    editable:     false,
    width:        320,
  },
]

const columnsWF09= (canEdit, showHolotipo) => [
  {
    field:        'id',
    headerName:   'ID',
    type:         'number',
    editable:     false,
    hide:         true,
  },
  {
    field:        'mid',
    headerName:   'mid',
    editable:     false,
    hide:         true,
  },
  {
    field:        'origen',
    headerName:   'Origen',
    editable:     false,
    hide:         true,
  },
  {
    field:        'taxonrank',
    headerName:   'Rango',
    editable:     false,
  },
  {
    field:        'scientificname',
    headerName:   'Nombre científico',
    editable:     false,
    width:        300,
  },
  {
    field:        'metodo',
    headerName:   'Método',
    editable:     canEdit,
    width:        300,
  },
  {
    field:        'tratamiento',
    headerName:   'Tratamiento',
    editable:     canEdit,
    width:        300,
  },
  {
    field:        'holotipo',
    headerName:   'Holotipo',
    type:         'boolean',
    editable:     false,
    width:        120
  },
  // {
  //   field:        'tipo',
  //   headerName:   'Muestra/lote',
  //   editable:     false,
  // },
  {
    field:        'cantidadSolicitada',
    headerName:   'Cantidad declarada ATM',
    type:         'number',
    editable:     false,
    hide:         true,
    width:        180,
  },
  {
    field:        'saldoHolotipos',
    headerName:   'Cantidad autorizada',
    editable:     false,
    type:         'number',
    width:        120,
    hide:         false,
  },
  {
    field:        'holotiposSolicitados',
    headerName:   'Cantidad soloicitada',
    type:         'number',
    editable:     true,
    width:        120,
  },
  {
    field:        'centroDocumentacion',
    headerName:   'Centro Documentación',
    type:         'number',
    headerAlign:  'left',
    editable:     false,
    hide:         true,
    width:        320,
  },
]
