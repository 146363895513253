import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {MyAppBar} from 'src/features/App'
import {Bandejas} from 'src/features/App/subcomponents/Bandejas'
import {Routes, Route} from 'react-router-dom'
import {P01Solicitud} from 'src/features/P01Solicitud'
import {P10ATM} from 'src/features/P10ATM'
import {P07DeclaracionMovilizacion} from 'src/features/P07PedidoMovilizacion'
import {P08PermisoImportacion} from 'src/features/P08PermisoImportacion'
import {P11PermisoExportacion} from 'src/features/P11PermisoExportacion'
import {TareasInvestigador} from 'src/features/App/subcomponents/TareasInvestigador'
import {P0102RO} from 'src/features/P01Solicitud/P0102RO'
import {fetchProvincias} from 'src/features/App/sliceApp'
import {useDispatch} from 'react-redux'
import {
  fetchBosques,
  fetchAreasProtegidas,
  fetchFuncionarios,
  fetchInstituciones,
  fetchSetCentrosDocumentacion,
} from 'src/features/App/sliceApp'
import P10ATMRO from 'src/features/P10ATM/P10ATMSolicitudRO'
import {P13Dictamen} from 'src/features/P13Dictamen'
import {P15Seguimiento} from 'src/features/P15SegumientoAutorizacion'
import {P09Holotipos} from "src/features/P09SalidaHolotipos"
import {P16CerrarAutorizacion} from "src/features/P16CerrarAutorizacion"
import {P17EvaluarCumplimiento} from "src/features/P17EvaluarCumplimiento"
import {Administracion} from "src/features/Administracion"
import {Preferencias} from "src/features/Preferencias"
import P10ATMSolicitudRO from "src/features/P10ATM/P10ATMSolicitudRO"
import {TablaFuncionarios} from './features/Administracion/TablaFuncionarios'
import {TablaInvestigadores} from './features/Administracion/TablaInvestigadores'
import {TablaBosques} from './features/Administracion/TablaBosques'
import {TablaInstitutos} from './features/Administracion/TablaInstitutos'
import {P03Solicitud} from './features/P03'
import {P12retornoHolotipos} from "./features/P12regreso";
import GLOBALS from 'src/features/App/globals'
import {TablaFirmas} from "./features/Administracion/TablaFirmas";
import {TablaProcesos} from "./features/Administracion/TablaProcesos";
import {TablaAutorizaciones} from "./features/Administracion/TablaAutorizaciones";
import {TablaPlantillas} from "./features/Administracion/TablaPlantillas"
import {TablaAreas} from './features/Administracion/TablaAreas'
import {TablaLineas} from './features/Administracion/TablaLineas'
import {P14ResuladoExportaciones} from "./features/P14ResultadoExportacion"
import MyTimeout from "src/components/MyTimeout"
import {f} from "src/commons"
import P07SolicitudRO from "./features/P07PedidoMovilizacion/P07SolicitudRO";

// const timeout = 180_000
// const promptBeforeIdle = 5_000

const App = () => {
  const dispatch = useDispatch()
  const investigador = useSelector(state => state.app.investigador)
  const provincias =  useSelector(state => state.app.provincias)
  const usuario = useSelector(state => state.app.usuario)

  useEffect(() => {
      setTimeout(() => {
        dispatch(fetchProvincias())
      }, 3000)
  }, [])

  useEffect(() => {
    if(provincias.length > 0) {
      dispatch(fetchBosques(provincias))
      dispatch(fetchAreasProtegidas(provincias))
      dispatch(fetchFuncionarios())
      dispatch(fetchInstituciones())
      dispatch(fetchSetCentrosDocumentacion())
    }
  }, [provincias])

  GLOBALS.year = new Date().getFullYear()
  setInterval(() => {
    GLOBALS.year = new Date().getFullYear()
  }, 600000)

  return (
    <>
      <MyAppBar />
      <Routes>
        <Route path='/' element={investigador?<TareasInvestigador />:<Bandejas />} />
        <Route path='/solicitud' element={<P01Solicitud />} />
        <Route path='/atmro' element={<P10ATMRO />} />
        <Route path='/solicitudro' element={<P0102RO />} />
        <Route path='/pedidoMovilizacion' element={<P07DeclaracionMovilizacion />} />
        <Route path='/permisoImportacion' element={<P08PermisoImportacion />} />
        <Route path='/permisoExportacion' element={<P11PermisoExportacion />} />
        <Route path='/validarATM' element={<P10ATM />} />
        <Route path='/dictamen' element={<P13Dictamen />} />
        <Route path='/seguimiento' element={<P15Seguimiento />} />
        <Route path='/holotipos' element={<P09Holotipos />} />
        <Route path='/cerrarsolicitud' element={<P16CerrarAutorizacion />} />
        <Route path='/evaluar' element={<P17EvaluarCumplimiento />} />
        <Route path='/administracion' element={<Administracion />} />
        <Route path='/preferencias' element={<Preferencias />} />
        <Route path='/p10ro' element={<P10ATMSolicitudRO />} />
        <Route path='/p07ro' element={<P07SolicitudRO />} />
        <Route path='/funcionarios' element={<TablaFuncionarios />} />
        <Route path='/investigadores' element={<TablaInvestigadores />} />
        <Route path='/bosques' element={<TablaBosques />} />
        <Route path='/institutos' element={<TablaInstitutos />} />
        <Route path='/finesComerciales' element={<P03Solicitud />} />
        <Route path='/retornoHolotipos' element={<P12retornoHolotipos />} />
        <Route path='/resultadoExportaciones' element={<P14ResuladoExportaciones />} />
        <Route path='/firmas' element={<TablaFirmas />} />
        <Route path='/seguimientoProcesos' element={<TablaProcesos />} />
        <Route path='/autorizaciones' element={<TablaAutorizaciones />} />
        <Route path='/plantillas' element={<TablaPlantillas />} />
        <Route path='/areas' element={<TablaAreas />} />
        <Route path='/lineas' element={<TablaLineas />} />
        <Route path='*' element={<Bandejas />} />
      </Routes>
      <MyTimeout userDefined={f.isValid(usuario?.tipo)}/>
    </>
  )
}

export default App
