import React, {useEffect, useState} from 'react'
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box,
  CssBaseline,
  Grid,
  Stack, Tab,
  Typography,
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, common, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Autorizacion from "src/features/P01Solicitud/subcomponents/Autorizacion";
import {MyTextField} from "../../components/MyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyReadOnlyAreaTextField} from "../../components/MyReadOnlyAreaTextField";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MyButtonAlert} from "../../components/MyButtonAlert";
import {MyTable as MyTableObservaciones} from "../../components/MyTable";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                  // atms=[],
                }) => {
  const dispatch = useDispatch()
  const section = 'ValidarSolicitud'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct?.payload?.Solicitud?.solicitudAprobada)
  // const recursosExSitu = slct?.payload?.Solicitud?.solicitudAprobada?.father?.payload?.recursosES
  // const centroDocumentacion = slct?.payload?.Solicitud?.solicitudAprobada?.father?.payload?.origenES
  const ATM = slct?.payload?.Solicitud?.solicitudAprobada?.father?.payload // slct.payload.Solicitud.solicitudAprobada.father.aprobada
  let rss = ATM?.recursosES?.filter(it => it.holotipo === true)
  const mp = slct.payload.Solicitud.solicitudAprobada.father.abuelo.payload.solicitud
  const payload = slct.payload
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:              today,
    requisito01:        false,
    requisito02:        false,
    requisito03:        false,
    requisito04:        false,
    requisito05:        true,
    cumpleRequisitos:   false,
    observaciones:      [],
    asunto:             '',
    detalle:            '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  useEffect(() => {
    if(formValues.cumpleRequisitos) {
      setFormValues({
        ...formValues,
        asunto:  'Validación de la Solicitud de salida de holotipos APROBADA',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la validación del ` +
          `Permiso de Salida de Holotipos con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} HA SIDO ADMITIDO A TRÁMITA.\n\n`
      })
    } else {
      setFormValues({
        ...formValues,
        asunto:  'Validación de la Solicitud de salida de holotipos NEGADA',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la validación del ` +
          `Permiso de Salida de Holotipos con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} NO HA SIDO ADMITIDO A TRÁMITA.\n\n`
      })
    }
  }, [formValues.cumpleRequisitos])

  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin:0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Validación de solicitud de salida de holotipos'} />
        </AppBar>
        <TabContext value={myTab} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
            <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="ATM" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              {f.isValid(payload.T55000010_Activity_AsignarCasoSenescytWF09?.observaciones) && payload.T55000010_Activity_AsignarCasoSenescytWF09?.observaciones !== '' &&
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} style={{padding: '1rem 24px 1rem 0'}}>
                    <MyButtonAlert label={'Directriz del caso'}
                                   color0={'#888888'}
                                   color1={'#888888'}
                                   messageTitle='Directriz del caso'
                                   messageContent={payload.T55000010_Activity_AsignarCasoSenescytWF09?.observaciones}/>
                  </Stack>
                </Grid>
              }
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={slct.payload.Solicitud['fecha']} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false} />
              <Autorizacion solicitud={{numeroSolicitud: mp?.father?.aprobada?.numeroSolicitudPadre, payload: mp}} />
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Destino'}/>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <MyReadOnlyTextField id={'ubicacionOrigen'}*/}
              {/*                       label={'Origen'}*/}
              {/*                       value={ATM.Solicitud.destinoFinal} />*/}
              {/*</Grid>*/}
              <Grid item xs={6}>
                <MyReadOnlyTextField id={'ubicacionPais'}
                                     label={'Pais'}
                                     value={slct.payload.Solicitud.ubicacionPais} />
              </Grid>
              <Grid item xs={6}>
                <MyReadOnlyTextField id={'ubicacionInstitucion'}
                                     label={'Institución'}
                                     value={slct.payload.Solicitud.ubicacionInstitucion} />
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <MyUpload id={'certificadoDeposito'}*/}
              {/*            label={'Certificado de depósito'}*/}
              {/*            dir={instanciaProceso?.solicitud?.id}*/}
              {/*            canEdit={false}*/}
              {/*            formValues={slct.payload.Solicitud} />*/}
              {/*</Grid>*/}
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Recursos'}/>
              </Grid>
              <Grid item xs={12}>
                <MyTableRecursos2 id={'recursos'}
                                  canEdit={false}
                                  formValues={slct.payload.Solicitud}
                                  mode={'WF09'} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Justificación de la salida del holotipo'} />
              </Grid>
              <Grid item xs={8} >
                <MyReadOnlyTextField id={'responsableEnvio'}
                                     label={'Responsable del envío *'}
                                     icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>}
                                     value={slct.payload.Solicitud.responsableEnvio} />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} >
                <Box sx={{p:'0.9rem 1rem 0 0'}}>
                  <MyReadOnlyTextField id={'fechaRetorno'}
                                       label={'Fecha de retorno'}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>}
                                       value={slct.payload.Solicitud.fechaRetorno} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <MyReadOnlyAreaTextField id='justificacion'
                                         label={'Justificación de la salida *'}
                                         value={slct.payload.Solicitud.justificacion}
                                         icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 0.6rem 0 0'}}>
                  <MyUpload id={'certificadoDeposito'}
                            label={'Certificado de depósito'}
                            dir={instanciaProceso?.solicitud?.id}
                            canEdit={false}
                            formValues={slct.payload.Solicitud} />
                  <Box sx={{p:'0 0 0 0'}}>
                    <MySwitch id={'integridadHolotipo'}
                              label={'¿Se garantiza la integridad del holotipo en cuestión y su retorno en óptimas condiciones después del tiempo de salida establecido?'}
                              formValues={slct.payload.Solicitud}
                              canEdit={false} />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Requisitos'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito01'}
                          label={'¿La salida de holotipos ha sido debidamente autorizada por la entidad competente, conforme la normativa ecuatoriana vigente?'}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={handleChange}
                          canEdit={canEdit}/>
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito02'}
                          label={'¿La salida de holotipos se enmarca en un permiso de investigación/contrato autorizado previamente por la Autoridad competente?'}
                          formValues={formValues}
                          handleChange={handleChange}
                          canEdit />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito03'}
                          label={'¿La salida de holotipos se encuentra respaldada en un Acuerdo de Transferencia de Material previamente validado por la SENESCYT?'}
                          formValues={formValues}
                          handleChange={handleChange}
                          canEdit />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito04'}
                          label={'¿La salida de holotipos prevé el reporte de los resultados alcanzados.?'}
                          formValues={formValues}
                          handleChange={handleChange}
                          canEdit />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 1.8rem 0.5rem 2rem'}}>
                <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' >
                  <MySwitch id={'cumpleRequisitos'}
                            label={'¿La solicitud cumple con los Requisitos?'}
                            fullWidth={false}
                            formValues={formValues}
                            handleChange={handleChange}
                            canEdit />
                </Stack>
              </Grid>
              {/*<Grid item xs={12} sx={dialog.titleContainer}>*/}
              {/*  <MySubtitle subtitle={'Observaciones a la solicitud'} />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <MyTableObservaciones id={'observaciones'}*/}
              {/*                        formValues={formValues}*/}
              {/*                        setFormValues={setFormValues}*/}
              {/*                        columnName={'Observaciones *'}*/}
              {/*                        canEdit={canEdit}*/}
              {/*                        addItem={() => {*/}
              {/*                          if(formValues['observaciones'].filter(it => it === '').length === 0) {*/}
              {/*                            const field = 'observaciones'*/}
              {/*                            const newSet = [ ...formValues[field], '']*/}
              {/*                            const newFormValues = {...formValues, [field]:newSet}*/}
              {/*                            setFormValues(newFormValues)*/}
              {/*                          }*/}
              {/*                        }} />*/}
              {/*</Grid>*/}
              {/*<Grid container style={{padding:'0.6rem 0.8rem 0 0.6rem'}} justifyContent="flex-end">*/}
              {/*  <MySendButton disabled={ counter > 0 }*/}
              {/*                label={'Solicitar info'}*/}
              {/*                onSend={() => {*/}
              {/*                  const metadata = JSON.stringify({*/}
              {/*                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,*/}
              {/*                    "perfilUsuarioSolicitud": Number(mp?.father?.aprobada?.idPerfilUsuario),*/}
              {/*                    "cumpleRequisitos": true,*/}
              {/*                    "ampliacionInformacionSenescyt": true,*/}
              {/*                  })*/}
              {/*                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))*/}
              {/*                }} />*/}
              {/*</Grid>*/}
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificación'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
                <MyTextField id={'asunto'}
                             label={'Asunto *'}
                             formValues={formValues}
                             handleChange={handleChange}
                             canEdit={true} />
              </Grid>
              {
                formValues.asunto === ''?
                  <Typography sx={common.warnig}>Asunto es obligatorio</Typography>
                  :null
              }
              <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
                <MyAreaTextField id={'detalle'}
                                 label={'Contenido *'}
                                 formValues={formValues}
                                 handleChange={handleChange}
                                 canEdit={true} />
              </Grid>
              {
                formValues.detalle === ''?
                  <Typography sx={common.warnig}>Contenido es obligatorio</Typography>
                  :null
              }
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P10ATMRO payload={{Solicitud: slct?.payload?.Solicitud?.solicitudAprobada?.father?.payload}} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  {
                    (canEdit)?
                      <>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else {
                                           alert('Debe GUARDAR los cambios realizados')
                                         }
                                       }} />
                        <MyButtonBacan label={'Guardar'} onClick={() => {
                                         const newPayload= {...payload ,[section]: {...formValues}}
                                         newPayload.Solicitud.solicitudAprobada = JSON.stringify(newPayload?.Solicitud?.solicitudAprobada)
                                         dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                         setCounter(0)
                                       }}
                                       disabled={ counter <= 0 }
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={ counter > 0 || formValues.asunto === '' || formValues.detalle === '' }
                                      label={'enviar'}
                                      onSend={() => {
                                        const metadata = JSON.stringify({
                                          "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                          "cumpleRequisitos": formValues.cumpleRequisitos,
                                          "ampliacionInformacionSenescyt": false,
                                          "asunto": formValues.asunto,
                                          "detalle": formValues.detalle,
                                        })
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </>
                      :
                      <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => dispatch(handleClear())} />
                      </Grid>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}
