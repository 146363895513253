import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box, ButtonGroup,
  CssBaseline, FormControlLabel,
  Grid,
  InputAdornment, InputLabel, Link, Select,
  Stack, Tab,
  TextField,
  Typography
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, common, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import RemoveIcon from '@mui/icons-material/Remove'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import {MyTextField} from 'src/components/MyTextField'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {MyReadOnlyTextField} from "src/components/MyReadOnlyTextField"
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {MySwitch} from 'src/components/MySwitch'
import API from 'src/features/App/API'
import {MyUpload} from 'src/components/MyUpload'
import Toolbar from '@mui/material/Toolbar'
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {AccountCircle} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import {MyButtonBacan2} from "../../components/MyButtonBacan2";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {PageOrientation} from "docx"
import {MySelect} from "../../components/MySelect";
import BiotechIcon from "@mui/icons-material/Biotech";
import GLOBALS from "../App/globals";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import atmpdf from "../../plantillas/Plantilla-ATM.pdf";
import atmdocx from "../../plantillas/Plantilla-ATM.doc";
import {MyAutocompleteTextField} from "../../components/MyAutocompleteTextField";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {P0102Expediente} from "../P01Solicitud/P0102Expediente";
import {MyTypography} from "../../components/MyTypography";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  // mode='EX-SITU',
                  canEdit=true
}) => {
  const dispatch = useDispatch()
  const section = 'Solicitud'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const mp = slct.payload.Solicitud.solicitudAprobada
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const sa = JSON.parse(payload?.Solicitud?.solicitudAprobada ?? '{}')
  const lis = f.isValid(sa.RecursosInSitu?.laboratorios) ? sa.RecursosInSitu?.laboratorios : []
  const les = f.isValid(sa.RecursosExSitu?.laboratorios) ? sa.RecursosExSitu?.laboratorios : []
  const lises = [...lis, ...les]
  const rss = API.allResources(sa)
  const allCentros = API.allCentros(mp)
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                 today,
    tipo:                  'IN-SITU',  // 'IN-SITU' o 'EX-SITU' o "IN-EX-SITU"
    identificador:         solicitud.numeroSolicitud,
    aprobadaIdentificador: '',
    solicitudAprobada:     {},
    recursosIS:            [],
    destinoFinalIS:        '',
    origenES:              '',
    recursosES:            [],
    plazo:                 mp.Propuesta.plazo,
    destinoFinalES:        '',
    condicion1:            false,
    pagina1:               0,
    condicion2:            false,
    pagina2:               0,
    condicion3:            false,
    pagina3:               0,
    condicion4:            false,
    pagina4:               0,
    condicion5:            false,
    pagina5:               0,
    condicion6:            false,
    pagina6:               0,
    atm:                   '',
    atmAdicional:          '',
    docx:                  '',
    pdf:                   '',
    serial:                '',
    texto: `Yo, ${mp?.Solicitante?.nombresCompletos} portador del documento de identidad ${mp?.Solicitante?.cedula}, en calidad de solicitante, declaro bajo ` +
      'juramento que la información constante en la presente solicitud es verdadera y de mi absoluta ' +
      'responsabilidad. En caso de omitir información, así como en caso de forzar, falsificar, modificar, alterar o ' +
      'introducir cualquier información falsa o corregir el presente documento, asumo toda la responsabilidad ' +
      'administrativa, civil o penal conforme lo establecido por ley.\n\n'+
      'Atención: Por favor revise la información del registro de la solicitud, si está seguro que los datos son ' +
      'correctos acepte y declare la veracidad de toda la información detallada en la presente solicitud y envíe ' +
      'la misma; caso contrario, cierre esta ventana y realice los cambios a través del botón guardar.\n',
    si:                    false,
  }
  const [formValues, setFormValues] = useState({ ...emptyPayload, ...payload[section] })
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])

  const [evCantidadesIS, setEvCantidadesIS] = useState(true)
  const [evCantidadesES, setEvCantidadesES] = useState(true)

  useEffect(() => {
    let cantidadesIS = formValues.recursosIS.filter(it4=> it4.cantidadSolicitada === 0)
    setEvCantidadesIS(cantidadesIS.length === 0)
  }, [formValues.recursosIS])

  useEffect(() => {
    let cantidadesES = formValues.recursosES.filter(it4=> it4.cantidadSolicitada === 0)
    setEvCantidadesES(cantidadesES.length === 0)
  }, [formValues.recursosES])

  const defaultHandleChange4Switch = (
    event,
    value,
    canEdit,
    setFormValues,
    formValues
  ) => {
    if(canEdit) {
      setFormValues({...formValues, [event.target.id]: value})
    }
  }
  const handleChange = (e) => API.handleChange2numeric(e, canEdit, setFormValues, formValues)
  const handleChange2 = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const handleChange3 = (e) => API.handleChange2PositiveInteger(e, canEdit, setFormValues, formValues)
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => { setMytab(newTab); }
  const [dataLineIS, setDataLineIS] = React.useState('')
  const handleChangeDataLineIS = (e) => { setDataLineIS(e.target.value) }
  const [dataLineES, setDataLineES] = React.useState('')
  const handleChangeDataLineES = (e) => { setDataLineES(e.target.value) }
  const [rowSelectedIS, setRowSelectedIS] = useState('')
  const [rowSelectedES, setRowSelectedES] = useState('')
  const subfolder = 'recursos-atm'
  const filename = `recursos-atm-${formValues.identificador}`
  const filenameDOCX = `${filename}.docx`

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      const newPayload = {...payload, [section]: {...formValues}}
      dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
      setCounter(0)
    };
  }

  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Solicitud de acuerdo de transferencia de material'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="Autorización" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, mb:'4rem'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Declaración'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={formValues['fecha']} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false} />
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6}>
                <MyReadOnlyTextField id={'fecha'}
                                     label={'Fecha'}
                                     value={formValues.fecha}
                                     icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>}
                                     canEdit={false} />
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={8}>
                    <MyReadOnlyTextField id={'plazo'}
                                         label={'Plazo de ejecución'}
                                         value={formValues.plazo + ' meses'}
                                         icon={<AccessTimeIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Recursos a ser incluidos en el acuerdo de transferencia de material
                  </FormLabel>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={formValues.tipo}
                              onChange={(e) =>  {
                                const value = e.target.value
                                setFormValues({...formValues, tipo:f.isValid(value)?value:'IN-SITU' })
                              }}>
                    <FormControlLabel value='IN-SITU' disabled={formValues.recursosES?.length > 0} control={<Radio />} label='Recurso a ser recolectado (in situ)?' />
                    <FormControlLabel value='EX-SITU' disabled={formValues.recursosIS?.length > 0} control={<Radio />} label='Recurso depositado previamente (ex situ)' />
                    {/*<FormControlLabel value='IN-EX-SITU' control={<Radio />} label='Ambos, in situ y ex situ' />*/}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {(['IN-EX-SITU', 'IN-SITU'].includes(formValues.tipo) && (formValues.recursosES.length === 0))?
                <>
                  <Grid item xs={12} sx={dialog.titleContainer}>
                    <MySubtitle subtitle={'Recursos in-situ'} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant='standard' fullWidth>
                      <InputLabel id="demo-simple-select-label">Recurso</InputLabel>
                      <Select labelId="demo-simple-select-label"
                              id="dataLine-simple-select"
                              value={dataLineIS}
                              onChange={handleChangeDataLineIS} >
                        { rss?.filter(it => it.cantidadAutorizada > 0 && it.origen === 'IN-SITU').map((it, idx) => <MenuItem key={idx} value={it.mid}>{`${it.scientificname} (${it.submuestra})`}</MenuItem>) }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} >
                    <Box sx={{p:'0.8rem 0 0 0'}}>
                      <Stack direction={'row'} justifyContent='space-between' alignItems="center" spacing={2}>
                        <MyButtonBacan2 onClick={() => {
                                          if(dataLineIS !== '') {
                                            const filtro = rss.filter(it => it.mid === dataLineIS)
                                            if(filtro.length === 1) {
                                              const line = rss.filter(it => it.mid === dataLineIS)[0]
                                              const found = formValues.recursosIS.filter(it =>  it.mid === line.mid).length > 0
                                              if(!found) {
                                                let lineas = [...formValues.recursosIS]
                                                lineas.push(line)
                                                setFormValues({...formValues, recursosIS: lineas})
                                              }
                                            }
                                          }
                                        }}
                                        icon={AddIcon}
                                        label={'Agregar recurso'} />
                        {(rowSelectedIS !== '') &&
                          <MyButtonBacan2 onClick={() => {
                                            if(rowSelectedIS !== '') {
                                              const filtro = formValues.recursosIS.filter(it => it.mid !== rowSelectedIS)
                                              setFormValues({...formValues, recursosIS: filtro})
                                            }
                                          }}
                                          icon={RemoveIcon}
                                          label={'Eliminar recurso'} />
                        }
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTableRecursos2 id={'recursosIS'}
                                      canEdit={true}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      setRowSelected={setRowSelectedIS}
                                      mode={'ATM-IS'} />
                    { !evCantidadesIS &&
                      <MyTypography>Hay items con cantidades declaradas en 0</MyTypography>
                    }
                    { formValues.recursosIS.length === 0 &&
                      <MyTypography>No hay items</MyTypography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <MySubtitle subtitle={'Destino'} />
                  </Grid>
                  <Grid item xs={10} alignItems="center">
                    <MyAutocompleteTextField  id={'destinoFinalIS'}
                                              label={'Institución en la que se desarrollará la fase de laboratorio'}
                                              options={[...lis?.sort()]}
                                              setFormValues={setFormValues}
                                              icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                                              formValues={formValues}
                                              canEdit={canEdit}
                                              handleChange={handleChange2} />
                  </Grid>
                </>:null
              }
              {['IN-EX-SITU', 'EX-SITU'].includes(formValues.tipo)  && (formValues.recursosIS.length === 0) ?
                <>
                  <Grid item xs={12} sx={dialog.titleContainer}>
                    <MySubtitle subtitle={'Recursos ex-situ'}/>
                  </Grid>
                  <Grid item xs={12}>
                    <MySelect  id={'origenES'}
                               label={'Centro de documentación'}
                               data={allCentros}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit && formValues.recursosES.length === 0}
                               handleChange={handleChange} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant='standard' fullWidth>
                      <InputLabel id="select-label2">Recurso</InputLabel>
                      <Select labelId="select-label2"
                              id="simple-select2"
                              value={dataLineES}
                              onChange={handleChangeDataLineES}>
                        {rss?.filter(it => ((it.centroDocumentacion !== '') && (it.centroDocumentacion === formValues.origenES))).map((it, idx) =>
                          <MenuItem key={idx} value={it.mid}>{`${it.scientificname}`}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{p: '0.8rem 0 0 0'}}>
                      <Stack direction={'row'} justifyContent='space-between' alignItems="center" spacing={2}>
                        <MyButtonBacan2 onClick={() => {
                                          if (dataLineES !== '') {
                                            const filtro = rss.filter(it => it.mid === dataLineES)
                                            if (filtro.length === 1) {
                                              const line = rss.filter(it => it.mid === dataLineES)[0]
                                              const found = formValues.recursosES.filter(it => it.mid === line.mid).length > 0
                                              if (!found) {
                                                let lineas = [...formValues.recursosES]
                                                lineas.push(line)
                                                setFormValues({...formValues, recursosES: lineas})
                                              }
                                            }
                                          }
                                        }}
                                        icon={AddIcon}
                                        label={'Agregar recurso'}/>
                        {(rowSelectedES !== '') &&
                          <MyButtonBacan2 onClick={() => {
                                            if (rowSelectedES !== '') {
                                              const filtro = formValues.recursosES.filter(it => it.mid !== rowSelectedES)
                                              setFormValues({...formValues, recursosES: filtro})
                                            }
                                          }}
                                          icon={RemoveIcon}
                                          label={'Eliminar recurso'}/>
                        }
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTableRecursos2 id={'recursosES'}
                                      canEdit={true}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      setRowSelected={setRowSelectedES}
                                      showHolotipo={true}
                                      mode={'ATM-ES'}/>
                    {!evCantidadesES &&
                      <MyTypography>Hay items con cantidades declaradas en 0</MyTypography>
                    }
                    {formValues.recursosES.length === 0 &&
                      <MyTypography>No hay items</MyTypography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <MySubtitle subtitle={'Destino'}/>
                  </Grid>
                  <Grid item xs={10} alignItems="center">
                    <MyAutocompleteTextField id={'destinoFinalES'}
                                             label={'Institución en la que se desarrollará la fase de laboratorio'}
                                             options={[...les?.sort()]}
                                             setFormValues={setFormValues}
                                             icon={<BiotechIcon sx={{fontSize: '14px', color: 'silver'}}/>}
                                             formValues={formValues}
                                             canEdit={canEdit}
                                             handleChange={handleChange2}/>
                  </Grid>
                </> : null
              }
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {/*{*/}
                  {/*  formValues.serial === '' &&*/}
                  {/*  <ButtonGroup variant={'contained'}>*/}
                  {/*    <MyButtonBacan3 label={'GENERAR SERIAL'}*/}
                  {/*                    width={'11rem'}*/}
                  {/*                    bgColor1={'rgb(148 193 32)'}*/}
                  {/*                    onClick={() => {*/}
                  {/*                      const tipo = 'ATM'*/}
                  {/*                      API.secuenciaSet(tipo).then((ser) => {*/}
                  {/*                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {*/}
                  {/*                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`*/}
                  {/*                          // getOnSave({...formValues, serial:serial})()*/}
                  {/*                          setFormValues({...formValues, serial:serial})*/}
                  {/*                        }*/}
                  {/*                      })*/}
                  {/*                    }}*/}
                  {/*                    icon={RestartAltIcon}/>*/}
                  {/*  </ButtonGroup>*/}
                  {/*}*/}
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Listar recursos'}
                                      width={'11rem'}
                                      onClick={() => {
                                        const taggeDoc = {contenidoPlantilla:plantilla}
                                        const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, 'Senescyt', mapping(formValues), PageOrientation.LANDSCAPE, instanciaProceso?.solicitud?.nombreProyecto)
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                        })
                                      }}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.docx,
                                      })}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.pdf
                                      })}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Acuerdo de transferencia de material suscrito'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                  <Stack direction={'column'} alignItems="flex-start">
                    <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                      <Link href={atmpdf} underline={'none'} target="_blank">[ Formato sugerido ATM ]</Link>
                      {/*<Link href={atmdocx} underline={'none'} target="_blank">[ plantil|la docx]</Link>*/}
                    </Stack>
                    <MyUpload id={'atm'}
                              dir={instanciaProceso?.solicitud?.id}
                              label={'Acuerdo de transferencia de material *'}
                              formValues={formValues}
                              setFormValues={setFormValues} />
                  </Stack>
                  <MyUpload id={'atmAdicional'}
                            dir={instanciaProceso?.solicitud?.id}
                            label={'Documento adicional'}
                            formValues={formValues}
                            canEdit={true}
                            setFormValues={setFormValues} />
                </Stack>
              </Grid>

              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Cláusulas'} />
              </Grid>
              <Grid item xs={10} alignItems="center">
                <Box sx={{p:'1rem 0 0 0'}}>
                  <MySwitch id={'condicion1'}
                            label={'¿El ATM contempla una cláusula de divulgación obligatoria del país de origen, fuente o proveedor de los recursos?'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                            canEdit={canEdit} />
                </Box>
                {
                  (formValues.condicion1 && Number(formValues.pagina1) === 0)?
                    <MyTypography sx={common.warnig}>Número de página debeser mayor a 0</MyTypography>
                    :null
                }
              </Grid>
              <Grid item xs={2} >
                <Box sx={{p:'0.4rem 1rem 0 2rem', height:'4rem'}}>
                  {formValues['condicion1'] && <MyTextField id={'pagina1'}
                                                            label={'Página No.'}
                                                            canEdit={canEdit}
                                                            formValues={formValues}
                                                            isNumber={true}
                                                            setFormValues={setFormValues}
                                                            handleChange={handleChange3}/>}
                </Box>
              </Grid>
              <Grid item xs={10} >
                <Box sx={{p:'1rem 0 0 0'}}>
                  <MySwitch id={'condicion2'}
                            label={'¿El ATM define una cláusula de sometimiento a la legislación ecuatoriana en materia de propiedad intelectual, incluyendo la prohibición de patentar recursos biológicos, genéticos o sus derivados de origen ecuatoriano?'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                            canEdit={canEdit} />
                </Box>
                {
                  (formValues.condicion2 && Number(formValues.pagina2) === 0)?
                    <MyTypography sx={common.warnig}>Número de página no puede ser 0</MyTypography>
                    :null
                }
              </Grid>
              <Grid item xs={2} >
                <Box sx={{p:'0.4rem 1rem 0 2rem', height:'4rem'}}>
                  {formValues['condicion2'] && <MyTextField id={'pagina2'}
                               label={'Página No.'}
                               canEdit={canEdit}
                               isNumber={true}
                               formValues={formValues}
                               setFormValues={setFormValues} handleChange={handleChange3}/>}
                </Box>
              </Grid>
              <Grid item xs={10} >
                <Box sx={{p:'1rem 0 0 0'}}>
                  <MySwitch id={'condicion3'}
                            label={'¿El ATM contine una cláusula que incluya la obligación de repatriar los recursos biológicos, genéticos o sus derivados y la información levantada a partir de estos, en caso de incumplimiento de los términos del Acuerdo.?'}
                            formValues={formValues}
                            isNumber={true}
                            setFormValues={setFormValues}
                            handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                            canEdit={canEdit} />
                </Box>
                {
                  (formValues.condicion3 && Number(formValues.pagina3) === 0)?
                    <Typography sx={common.warnig}>Número de página no puede ser 0</Typography>
                    :null
                }
              </Grid>
              <Grid item xs={2} >
                <Box sx={{p:'0.4rem 1rem 0 2rem', height:'4rem'}}>
                  {formValues['condicion3'] && <MyTextField id={'pagina3'}
                               label={'Página No.'}
                               isNumber={true}
                               canEdit={canEdit}
                               formValues={formValues}
                               setFormValues={setFormValues} handleChange={handleChange3}/>}
                </Box>
              </Grid>

              <Grid item xs={10} >
                <Box sx={{p:'1rem 0 0 0'}}>
                  <MySwitch id={'condicion4'}
                            label={'¿El ATM define la obligación de reportar los resultados alcanzados?'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                            canEdit={canEdit} />
                </Box>
                {
                  (formValues.condicion4 && Number(formValues.pagina4) === 0)?
                    <MyTypography sx={common.warnig}>Número de página no puede ser 0</MyTypography>
                    :null
                }
              </Grid>
              <Grid item xs={2} >
                <Box sx={{p:'0.4rem 1rem 0 2rem', height:'4rem'}}>
                  {formValues['condicion4'] && <MyTextField id={'pagina4'}
                               label={'Página No.'}
                               isNumber={true}
                               canEdit={canEdit}
                               formValues={formValues}
                               setFormValues={setFormValues} handleChange={handleChange3}/>}
                </Box>
              </Grid>
              <Grid item xs={10} >
                <Box sx={{p:'1rem 0 0 0'}}>
                  <MySwitch id={'condicion5'}
                            label={'¿El ATM define la obligación del receptor de no transferir a terceros los recursos recibidos?'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                            canEdit={canEdit} />
                </Box>
                {
                  (formValues.condicion5 && Number(formValues.pagina5) === 0)?
                    <MyTypography sx={common.warnig}>Número de página no puede ser 0</MyTypography>
                    :null
                }
              </Grid>
              <Grid item xs={2} >
                <Box sx={{p:'0.4rem 1rem 0 2rem', height:'4rem'}}>
                  {formValues['condicion5'] && <MyTextField id={'pagina5'}
                                                            label={'Página No.'}
                                                            canEdit={canEdit}
                                                            isNumber={true}
                                                            formValues={formValues}
                                                            setFormValues={setFormValues}
                                                            handleChange={handleChange3}/>}
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Box sx={{p:'1rem 0 0 0'}}>
                  <MySwitch id={'condicion6'}
                            label={'¿El ATM define el destino final del recurso transferido una vez alcanzando el objetivo?'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                            canEdit={canEdit} />
                </Box>
                {
                  (formValues.condicion6 && Number(formValues.pagina6) === 0)?
                    <MyTypography sx={common.warnig}>Número de página no puede ser 0</MyTypography>
                    :null
                }
              </Grid>
              <Grid item xs={2} >
                <Box sx={{p:'0.4rem 1rem 0 2rem', height:'4rem'}}>
                  {formValues['condicion6'] && <MyTextField id={'pagina6'}
                               label={'Página No.'}
                               isNumber={true}
                               canEdit={canEdit}
                               formValues={formValues}
                               setFormValues={setFormValues} handleChange={handleChange3}/>}
                </Box>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Declaración de veracidad de la información'} />
              </Grid>
              <Grid item xs={12}>
                <TextField id='texto'
                           multiline
                           rows={10}
                           value={formValues.texto}
                           fullWidth
                           variant='standard'
                           aria-readonly={true}
                           sx={dialog.textTypography}
                           InputProps={{
                             disableUnderline: true,
                             startAdornment:(
                               <InputAdornment position="start">
                                 <AccountCircle sx={{fontSize: '14px', color:'silver'}}/>
                               </InputAdornment>
                             ),
                             sx: {
                               fontSize: '12px',
                               backgroundColor: 'transparent',
                             }
                           }}
                           InputLabelProps={{
                             sx: {
                               fontSize: '14px',
                             }
                           }} />
              </Grid>
              <Grid item xs={12}>
                <MySwitch id={'si'}
                          label={'Aceptar y enviar'}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          fullWidth={false}
                          canEdit={canEdit} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={slct.payload.Solicitud.solicitudAprobada} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              {
                (canEdit)?
                  <>
                    <Grid item xs={12} style={{padding:'0 4rem 0 4rem'}}>
                      <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else
                                           alert('Debe GUARDAR los cambios realizados')
                                       }} />

                        <MyButtonBacan label={'Guardar'}
                                       onClick={getOnSave()}
                                       disabled={counter <= 0 }
                                       icon={SaveOutlinedIcon} />

                        <MySendButton disabled={
                                        counter > 0 ||
                                        formValues.atm === '' ||
                                        // formValues.docx === '' ||
                                        (formValues.condicion1 === true && Number(formValues.pagina1) === 0) ||
                                        (formValues.condicion2 === true && Number(formValues.pagina2) === 0) ||
                                        (formValues.condicion3 === true && Number(formValues.pagina3) === 0) ||
                                        (formValues.condicion4 === true && Number(formValues.pagina4) === 0) ||
                                        (formValues.condicion5 === true && Number(formValues.pagina5) === 0) ||
                                        (formValues.condicion6 === true && Number(formValues.pagina6) === 0) ||
                                        // (formValues.destinoFinal === 0) ||
                                        !evCantidadesIS || !evCantidadesES ||formValues.si === false
                                      }
                                      label={'enviar'}
                                      onSend={ () => {
                                        const metadata = JSON.stringify({"solicitudId": `${instanciaProceso?.solicitud?.id}`,})
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </Stack>
                    </Grid>
                  </>
                  :
                  <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                    <MyButtonBacan label={'Regresar'}
                                   icon={ArrowBackIcon}
                                   onClick={() => dispatch(handleClear())} />
                  </Grid>
              }
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}

const plantilla = '' +
  '${TITULO1}Recursos del Acuerdo de Transferencia de Material$' +
  '${SALTO}${TITULO1}<<serial>>' +
  '${SALTO}${TITULO1}Recursos IN SITU' +
  '${SALTO}${RECURSOSATMIS}<<recursosATMIS>>' +
  '${SALTO}${TITULO1}Recursos EX SITU' +
  '${SALTO}${RECURSOSATMES}<<recursosATMES>>'

const mapping = (fv) => {
  return {
    '<<serial>>': fv.serial,
    '<<recursosATMIS>>':JSON.stringify(fv.recursosIS),
    '<<recursosATMES>>':JSON.stringify(fv.recursosES)
  }
}
