import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  AppBar,
  Box,
  ButtonGroup,
  CssBaseline, FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material'
import {format, parse, add} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {AccountCircle, Email, PhoneInTalk, Smartphone} from "@mui/icons-material"
import {MyMultipleSelect} from "../../components/MyMultipleSelect"
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField"
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {MySelect} from "../../components/MySelect"
import {MyDatePicker} from "../../components/MyDatePicker"
import {MyTextField} from "../../components/MyTextField"
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import BiotechIcon from '@mui/icons-material/Biotech'
import {MyTableRecolectores} from "../../components/MyTableRecolectores"
import {MySwitch} from "../../components/MySwitch"
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {MyButtonBacan2} from "../../components/MyButtonBacan2";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import GLOBALS from "../App/globals";
import RobotoCondensedRegular from "../../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {MyTypography} from "../../components/MyTypography";

export const P07Solicitud = ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                  atms=[]
                }) => {
  const dispatch = useDispatch()
  const section = 'Solicitud'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const mp = slct.payload.Solicitud.solicitudAprobada
  const funcionarios = useSelector(state => state.app.funcionarios)
  const rss = API.allResources(mp, 'IN-EX-SITU')
  const allCentros = API.allCentros(mp)
  const rssIS = rss?.filter(it => it.origen==='IN-SITU')
  const rssES = rss?.filter(it => it.origen==='EX-SITU')
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd MMMM yyyy hh:mm', {locale: es})
  const emptyPayload = {
    fecha:                     today,
    tipo:                      'IN-SITU',  // 'IN-SITU' o 'EX-SITU' o "IN-EX-SITU"
    serial:                    '',
    aprobadaIdentificador:     '',
    solicitudAprobada:         {},
    provincias:                [...mp.RecursosInSitu.provincias],
    areasProtegidas:           [],
    bosquesProtectores:        [],
    tipoTransporte:            '',
    fechaInicio:               format(new Date(), 'dd-MMMM-yyyy', {locale: es}),
    fechaFinal:                format(add(new Date(),{days:1}), 'dd-MMMM-yyyy', {locale: es}),
    recursosIS:                [],
    origenES:                  '',
    recursosES:                [],
    provinciaIS:               '',
    cantonIS:                  '',
    parroquiaIS:               '',
    rutaIS:                    '',
    laboratoriosIS:            '',
    provinciaCD:               '',
    cantonCD:                  '',
    parroquiaCD:               '',
    provinciaES:               '',
    cantonES:                  '',
    parroquiaES:               '',
    rutaES:                    '',
    laboratoriosES:            '',
    responsablesRecoleccion:   mp?.Personal?.personal.map(it => ({...it, recolecta:false, transporta:false})),
    responsableTransportacion: '',
    responsableCedula:         '',
    responsableContacto:       '',
    responsableCorreo:         '',
    ruta:                      '',
    solicitudATM:              (atms?.length > 0)?atms[0].codigoSolicitud:'',
    identificador:             solicitud.numeroSolicitud,
    docxGenerado:              false,
    docx:                      '',
    pdf:                       '',
    docxLded:                  '',
    pdfLded:                   '',
    pdfSigned:                 '',
    texto: `Yo, ${mp?.Solicitante?.nombresCompletos} portador del documento de identidad ${mp?.Solicitante?.cedula}, en calidad de solicitante, declaro bajo ` +
           'juramento que la información constante en la presente solicitud es verdadera y de mi absoluta ' +
           'responsabilidad. En caso de omitir información, así como en caso de forzar, falsificar, modificar, alterar o ' +
           'introducir cualquier información falsa o corregir el presente documento, asumo toda la responsabilidad ' +
           'administrativa, civil o penal conforme lo establecido por ley.\n\n'+
           'Atención: Por favor revise la información del registro de la solicitud, si está seguro que los datos son ' +
           'correctos acepte y declare la veracidad de toda la información detallada en la presente solicitud y envíe ' +
           'la misma; caso contrario, cierre esta ventana y realice los cambios a través del botón guardar.\n',
    si:                        false,
  }
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [recursoSelected, setRecursoSelected] = useState({})
  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value)) {
      API.handleChange(e, canEdit?'entrada':'*', setFormValues, formValues)
    }
  }
  const emailError = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formValues.responsableCorreo)?'':'No tiene el formato de correo electrónico'
  const [muestras0, setMuestras0] = useState({})
  const [payloadPC,setPayloadPC] = useState({})
  const [padreId,setPadreId] = useState(0)
  useEffect(() => {
    let solicitudPadreId = instanciaTarea.instanciaProcesoSolicitudPadreId
    if(!f.isValid(solicitudPadreId)) {
      const arr = instanciaTarea.instanciaProcesoNumeroSolicitudPadre?.split('-')
      solicitudPadreId = arr[arr?.length - 1]
    }
    setPadreId(Number(solicitudPadreId))
    API.fetchSolitudById(Number(solicitudPadreId)).then(slct => {
      setPayloadPC(JSON.parse(slct.payload))
      setMuestras0(JSON.parse(slct.payload)['InformeTecnicoMaate']['muestras'])
    })
  },[instanciaTarea.instanciaProcesoNumeroSolicitudPadre])
  const dInicio = parse(formValues.fechaInicio,'dd-MMMM-yyyy', new Date(), {locale: es})
  const dFinal = parse(formValues.fechaFinal,'dd-MMMM-yyyy', new Date(), {locale: es})
  const dToday = parse(format(new Date(), 'dd-MMMM-yyyy',{locale: es}),'dd-MMMM-yyyy', new Date(), {locale: es})
  const dResolucion = parse(mp?.Resolucion?.fecha,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Number(mp?.Propuesta?.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => { setMytab(newTab); }
  const [dataLineIS, setDataLineIS] = React.useState('');
  const [dataLineES, setDataLineES] = React.useState('');
  const [rowSelectedIS, setRowSelectedIS] = useState('')
  const [rowSelectedES, setRowSelectedES] = useState('')
  const handleChangeDataLineIS = (event) => { setDataLineIS(event.target.value) }
  const handleChangeDataLineES = (event) => { setDataLineES(event.target.value) }
  const [evCantidadesIS, setEvCantidadesIS] = useState(true)
  useEffect(() => {
    let cantidades = formValues.recursosIS.filter(it4=> Number(it4.cantidadSolicitada) === 0)
    setEvCantidadesIS(cantidades.length === 0)
  }, [formValues.recursosIS])
  const [evCantidadesES, setEvCantidadesES] = useState(true)
  useEffect(() => {
    let cantidades = formValues.recursosES.filter(it4=> Number(it4.cantidadSolicitada) === 0)
    setEvCantidadesES(cantidades.length === 0)
  }, [formValues.recursosES])
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `reporte-drm-${nombreOrganizacion}`
  const filename = `reporte-drm-${formValues.identificador}-${nombreOrganizacion}`
  const filenameDOCX = `${filename}-firmado.docx`
  const autorizador = ''
  const coordinador = ''
  const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]
  const sa = JSON.parse(payload?.Solicitud?.solicitudAprobada)
  const lis = f.isValid(sa.RecursosInSitu?.laboratorios) ? sa.RecursosInSitu?.laboratorios : []
  const les = f.isValid(sa.RecursosExSitu?.laboratorios) ? sa.RecursosExSitu?.laboratorios : []
  const lises = [...lis, ...les]
  function getOnSave(fv=null) {
    return () => {
      const newPayload = {...payload, [section]: (f.isValid(fv)?fv:{...formValues, fecha: today})}
      dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
      setCounter(0)
    }
  }

  ///////// DPA IS /////////////////////////////////////////////////////////////////////////////////
  const dpa_provinciasIS = [...API.dpa.filter(it => it.tipo === 'provincia')]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1)
  const provinciaCode = (f.isValidNotEmpty(formValues.provinciaIS))?dpa_provinciasIS.filter(it => it.nombre === formValues.provinciaIS)[0].codigo:''
  const dpa_cantonesIS = [...API.dpa.filter(it => it.sup === provinciaCode)]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1)
  const cantonCode = (f.isValidNotEmpty(formValues.cantonIS))?dpa_cantonesIS.filter(it => it.nombre === formValues.cantonIS)?.at(0)?.codigo:'' ?? ''
  const dpa_parroquiasIS = [...API.dpa.filter(it => it.sup === cantonCode)]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1)

  ///////// DPA ES /////////////////////////////////////////////////////////////////////////////////
  const dpa_provinciasES = [...API.dpa.filter(it => it.tipo === 'provincia')]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1)
  const provinciaCodeES = (f.isValidNotEmpty(formValues.provinciaES))?dpa_provinciasES.filter(it => it.nombre === formValues.provinciaES)[0].codigo:''
  const dpa_cantonesES = [...API.dpa.filter(it => it.sup === provinciaCodeES)]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1)
  const cantonCodeES = (f.isValidNotEmpty(formValues.cantonES))?dpa_cantonesES.filter(it => it.nombre === formValues.cantonES)?.at(0)?.codigo:'' ?? ''
  const dpa_parroquiasES = [...API.dpa.filter(it => it.sup === cantonCodeES)]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1)

  ///////// DPA CD /////////////////////////////////////////////////////////////////////////////////
  const dpa_provinciasCD = [...API.dpa.filter(it => it.tipo === 'provincia')]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1)
  const provinciaCodeCD = (f.isValidNotEmpty(formValues.provinciaCD))?dpa_provinciasCD.filter(it => it.nombre === formValues.provinciaCD)[0].codigo:''
  const dpa_cantonesCD = f.isValidNotEmpty(provinciaCodeCD)?[...API.dpa.filter(it => it.sup === provinciaCodeCD)]?.sort((it1, it2) => it1.nombre > it2.nombre ?1:it1.nombre === it2.nombre?0:-1):[]
  const cantonCodeCD = (f.isValidNotEmpty(formValues.cantonCD))?(dpa_cantonesCD.filter(it => it.nombre === formValues.cantonCD)[0]?.codigo):''
  const dpa_parroquiasCD = f.isValid(cantonCodeCD)?[...API.dpa.filter(it => it.sup === cantonCodeCD)]:[]

  const [allInSitu, setAllInSitu] = useState(false)
  const [allExSitu, setAllExSitu] = useState(false)

  useEffect(() => {
    let validar = (formValues.si === true) &&
      (formValues.tipoTransporte !== '')  &&
      ((formValues.responsablesRecoleccion?.filter(it => it.transporta === true)?.length > 0) || (formValues.responsableTransportacion !== '')) &&
      (formValues.responsablesRecoleccion?.filter(it => it.recolecta === true)?.length > 0) &&
      (dInicio >= dResolucion) && (dFinal <= dPlazo) && (dPlazo >= dInicio) && (dInicio < dFinal)

    if(formValues.tipo === 'IN-SITU') {
      validar = validar && evCantidadesIS && (formValues.laboratoriosIS !== '')
        && (formValues.provinciaIS !== '') && (formValues.cantonIS !== '') && (formValues.parroquiaIS !== '')
      setAllInSitu(validar)
    }
    if(formValues.tipo === 'EX-SITU') {
      validar = validar && evCantidadesES  && (formValues.laboratoriosES !== '')
        && (formValues.provinciaES !== '') && (formValues.cantonES !== '') && (formValues.parroquiaES !== '')
      setAllExSitu(validar)
    }
  },[formValues])

  const provinciasId = useSelector(state => state.app.provinciasId)
  const areasProtegidas0 = useSelector(state => state.app.areasProtegidas)
  const bosquesProtectores0 = useSelector(state => state.app.bosques)
  const [areasProtegidas, setAreasProtegidas] = useState([])
  const [bosquesProtectores, setBosquesProtectores] = useState([])

  useEffect(() => {
    const provSltd = provinciasId.filter(it => formValues.provincias.includes(it.nombreProvincia))
    const provSltdId = provSltd.map(it => it.id)
    setAreasProtegidas(areasProtegidas0.filter(it=> provSltdId.includes(it.provinciaId)).filter(it => mp.RecursosInSitu.areasProtegidas.includes(it.nombre)))
    setBosquesProtectores(bosquesProtectores0.filter(it=> provSltdId.includes(it.idProvincia)).filter(it => mp.RecursosInSitu.bosquesProtectores.includes(it.nombre)) )

  }, [formValues.provincias])

  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
            <SectionTitle title={'Declaración de Movilización de Recursos'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="Autorización" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Declaración'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={formValues['fecha']} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false} />

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Recursos de la biodiversidad a ser movilizados
                  </FormLabel>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={formValues.tipo}
                              onChange={(e) =>  {
                                const value = e.target.value
                                setFormValues({...formValues, tipo:f.isValid(value)?value:'IN-SITU' })
                              }}>
                    <FormControlLabel value='IN-SITU' control={<Radio />} disabled={formValues.recursosES.length > 0} label='Recurso a ser recolectado (in situ)?' />
                    <FormControlLabel value='EX-SITU' control={<Radio />} disabled={formValues.recursosIS.length > 0} label='Recurso depositado previamente (ex situ)' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {(rssIS.length > 0 && ['IN-SITU', 'IN-EX-SITU'].includes(formValues.tipo))?
                <>
                  <Grid item xs={12} sx={dialog.titleContainer}>
                    <MySubtitle subtitle={'Recursos IN SITU'}/>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant='standard' fullWidth>
                      <InputLabel id="demo-simple-select-label">Recurso</InputLabel>
                      <Select labelId="demo-simple-select-label"
                              id="dataLine-simple-select"
                              value={dataLineIS}
                              onChange={handleChangeDataLineIS} >
                        { rssIS?.map(it => <MenuItem key={it.mid} value={it.mid}>{`${it.scientificname} (${it.submuestra}) `}</MenuItem>) }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} >
                    <Box sx={{p:'0.8rem 0 0 0'}}>
                      <Stack direction={'row'} justifyContent='space-between' alignItems="center" spacing={2}>
                        <MyButtonBacan2 onClick={() => {
                                          if(dataLineIS !== '') {
                                            const filtro = rss.filter(it => it.mid === dataLineIS)
                                            if(filtro.length === 1) {
                                              const line = rss.filter(it => it.mid === dataLineIS)[0]
                                              const found = formValues.recursosIS.filter(it =>  it.mid === line.mid).length > 0
                                              if(!found) {
                                                let lineas = [...formValues.recursosIS]
                                                lineas.push(line)
                                                setFormValues({...formValues, recursosIS: lineas})
                                              }
                                            }
                                          }
                                        }}
                                        icon={AddIcon}
                                        label={'Agregar recurso'} />
                        {(rowSelectedIS !== '') &&
                          <MyButtonBacan2 onClick={() => {
                                            if(rowSelectedIS !== '') {
                                              const filtro = formValues.recursosIS.filter(it => it.mid !== rowSelectedIS)
                                              setFormValues({...formValues, recursosIS: filtro})
                                            }
                                          }}
                                          icon={RemoveIcon}
                                          label={'Eliminar recurso'} />
                        }
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTableRecursos2 id={'recursosIS'}
                                      canEdit={true}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      setRowSelected={setRowSelectedIS}
                                      mode={'ATM-IS'} />
                    { !evCantidadesIS &&
                      <MyTypography>Tabla de <strong>recursos</strong>: Hay items con cantidades declarada igual a 0</MyTypography>
                    }
                    { formValues.recursosIS.length === 0 &&
                      <MyTypography>Tabla de <strong>recursos</strong>: no tiene items</MyTypography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <MySubtitle subtitle={'Origen de los recursos'} />
                  </Grid>
                  <Grid item xs={6} >
                    <MyMultipleSelect id={'provincias'}
                                      label={'Provincias *'}
                                      data={mp.RecursosInSitu.provincias?.sort()}
                                      setFormValues={setFormValues}
                                      formValues={formValues}
                                      canEdit={canEdit && (formValues.bosquesProtectores.length === 0) && (formValues.areasProtegidas.length === 0)}
                                      handleChange={handleChange} />
                  </Grid>
                  <Grid container sx={{p:'0 0 0 0.5rem'}}>
                    <Grid item xs={6} >
                      <MyMultipleSelect id={'areasProtegidas'}
                                        label={'Áreas protegidas'}
                                        data={areasProtegidas.map(it => it.nombre)}
                                        setFormValues={setFormValues}
                                        formValues={formValues}
                                        canEdit={canEdit}
                                        handleChange={handleChange} />
                    </Grid>
                    <Grid item xs={6} >
                      <MyMultipleSelect id={'bosquesProtectores'}
                                        label={'Bosques Protectores'}
                                        data={bosquesProtectores.map(it => it.nombre)}
                                        setFormValues={setFormValues}
                                        formValues={formValues}
                                        canEdit={canEdit}
                                        handleChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} >
                      <MySubtitle subtitle={'Destino de los recursos'} />
                    </Grid>
                    <Grid item xs={12} >
                      <MySelect id={'laboratoriosIS'}
                               label={'Institución en la que se desarrolla la fase de laboratorio'}
                               data={mp.RecursosInSitu.laboratorios}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit}
                               handleChange={handleChange} />
                    </Grid>
                    <Grid item xs={4} alignItems="center" sx={{pr:'1rem'}}>
                      <MySelect  id={'provinciaIS'}
                                 label={'Provincia'}
                                 data={dpa_provinciasIS.map(it => it.nombre)}
                                 setFormValues={setFormValues}
                                 icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                                 formValues={formValues}
                                 canEdit={canEdit}
                                 andleChange={handleChange} />
                    </Grid>
                    <Grid item xs={4} alignItems="center"  sx={{p:'0 1rem 0 1rem'}}>
                      <MySelect  id={'cantonIS'}
                                 label={'Cantón'}
                                 data={[...dpa_cantonesIS.map(it => it.nombre), '']}
                                 setFormValues={setFormValues}
                                 icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                                 formValues={formValues}
                                 canEdit={canEdit}
                                 handleChange={handleChange} />
                    </Grid>
                    <Grid item xs={4} alignItems="center" sx={{pl:'1rem'}}>
                      <MySelect  id={'parroquiaIS'}
                                 label={'Parroquia'}
                                 data={[...dpa_parroquiasIS.map(it => it.nombre), '']}
                                 setFormValues={setFormValues}
                                 icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                                 formValues={formValues}
                                 canEdit={canEdit}
                                 handleChange={handleChange} />
                    </Grid>
                  </Grid>
                </>:null
              }
              {
                (rssES.length > 0  && ['EX-SITU', 'IN-EX-SITU'].includes(formValues.tipo)) ?
                <>
                  <Grid item xs={12} sx={dialog.titleContainer}>
                    <MySubtitle subtitle={'Recursos EX SITU'}/>
                  </Grid>
                  <Grid item xs={12}>
                    <MySubtitle subtitle={'Origen de los recursos'} />
                  </Grid>
                  <Grid item xs={12}>
                    <MySelect  id={'origenES'}
                               label={'Centro de documentación'}
                               data={allCentros}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit && formValues.recursosES.length === 0}
                               handleChange={handleChange} />
                  </Grid>
                  <Grid item xs={4} alignItems="center" sx={{pr:'1rem'}}>
                    <MySelect  id={'provinciaCD'}
                               label={'Provincia'}
                               data={dpa_provinciasCD.map(it => it.nombre)}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit && (formValues.recursosES.length === 0) && (formValues.origenES !== '') && (formValues.docx === '')}
                               onChange={(e) => {
                                 if(canEdit && (formValues.recursosES.length === 0) && (formValues.origenES !== '') && (formValues.docx === '')) {
                                   setFormValues({...formValues, ['provinciaCD']: e.target.value, ['cantonCD']: '', ['parroquiaCD']: ''})
                                 }
                               }} />
                  </Grid>
                  <Grid item xs={4} alignItems="center"  sx={{p:'0 1rem 0 1rem'}}>
                    <MySelect  id={'cantonCD'}
                               label={'Cantón'}
                               data={[...dpa_cantonesCD.map(it => it.nombre), '']}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit && (formValues.recursosES.length === 0) && (formValues.origenES !== '') && (formValues.docx === '')}
                               onChange={(e) => {
                                 if(canEdit && (formValues.recursosES.length === 0) && (formValues.origenES !== '') && (formValues.docx === '')) {
                                   setFormValues({...formValues, ['cantonCD']: e.target.value, ['parroquiaCD']: ''})
                                 }
                               }} />
                  </Grid>
                  <Grid item xs={4} alignItems="center" sx={{pl:'1rem'}}>
                    <MySelect  id={'parroquiaCD'}
                               label={'Parroquia'}
                               data={dpa_parroquiasCD.map(it => it.nombre)}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit && (formValues.recursosES.length === 0) && (formValues.origenES !== '')}
                               onChange={(e) => {
                                 if(canEdit && (formValues.recursosES.length === 0) && (formValues.origenES !== '') && (formValues.docx === '')) {
                                   setFormValues({...formValues, ['parroquiaCD']: e.target.value })
                                 }
                               }}/>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant='standard' fullWidth>
                      <InputLabel id="demo-simple-select-labe2l">Recurso</InputLabel>
                      <Select labelId="demo-simple-select-label2"
                              id="dataLine-simple-select2"
                              value={dataLineES}
                              onChange={handleChangeDataLineES} >
                        { rssES?.filter(it => it.centroDocumentacion === formValues.origenES)?.map(it => <MenuItem key={it.mid} value={it.mid}>{it.scientificname}</MenuItem>) }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} >
                    <Box sx={{p:'0.8rem 0 0 0'}}>
                      <Stack direction={'row'} justifyContent='space-between' alignItems="center" spacing={2}>
                        <MyButtonBacan2 onClick={() => {
                                          if(dataLineES !== '') {
                                            const filtro = rss.filter(it => it.mid === dataLineES)
                                            if(filtro.length === 1) {
                                              const line = rss.filter(it => it.mid === dataLineES)[0]
                                              const found = formValues.recursosES.filter(it =>  it.mid === line.mid).length > 0
                                              if(!found) {
                                                let lineas = [...formValues.recursosES]
                                                lineas.push(line)
                                                setFormValues({...formValues, recursosES: lineas})
                                              }
                                            }
                                          }
                                        }}
                                        icon={AddIcon}
                                        disabled={(formValues.provinciaCD === '') || (formValues.cantonCD === '') || (formValues.parroquiaCD === '') || (formValues.docx !== '')}
                                        label={'Agregar recurso'} />
                        {(rowSelectedES !== '') &&
                          <MyButtonBacan2 onClick={() => {
                                            if(rowSelectedES !== '') {
                                              const filtro = formValues.recursosES.filter(it => it.mid !== rowSelectedES)
                                              setFormValues({...formValues, recursosES: filtro})
                                            }
                                          }}
                                          disabled={(formValues.provinciaCD === '') || (formValues.cantonCD === '') || (formValues.parroquiaCD === '') || (formValues.docx !== '')}
                                          icon={RemoveIcon}
                                          label={'Eliminar recurso'} />
                        }
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTableRecursos2 id={'recursosES'}
                                      canEdit={true}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      setRowSelected={setRowSelectedES}
                                      showHolotipo={true}
                                      mode={'ATM-ES'} />
                    { !evCantidadesES &&
                      <MyTypography>Hay items con cantidad declarada igual a 0</MyTypography>
                    }
                    { formValues.recursosES.length === 0 &&
                      <MyTypography>No hay items</MyTypography>
                    }
                  </Grid>
                  <Grid item xs={12} >
                    <MySubtitle subtitle={'Destino de los recursos'} />
                  </Grid>
                  <Grid item xs={10} alignItems="center">
                    <MySelect id={'laboratoriosES'}
                              label={'Institución en la que se desarrolla la fase de laboratorio'}
                              data={[...mp.RecursosExSitu.laboratorios, '']}
                              setFormValues={setFormValues}
                              icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                              formValues={formValues}
                              canEdit={canEdit || (formValues.docx !== '')}
                              handleChange={handleChange} />
                  </Grid>
                  <Grid item xs={4} alignItems="center" sx={{pr:'1rem'}}>
                    <MySelect  id={'provinciaES'}
                               label={'Provincia'}
                               setFormValues={setFormValues}
                               data={[...dpa_provinciasES.map(it => it.nombre), '']}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit || (formValues.docx !== '')}
                               handleChange={handleChange} />
                  </Grid>
                  <Grid item xs={4} alignItems="center"  sx={{p:'0 1rem 0 1rem'}}>
                    <MySelect  id={'cantonES'}
                               label={'Cantón'}
                               data={[...dpa_cantonesES.map(it => it.nombre), '']}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit || (formValues.docx !== '')}
                               handleChange={handleChange} />
                  </Grid>
                  <Grid item xs={4} alignItems="center" sx={{pl:'1rem'}}>
                    <MySelect  id={'parroquiaES'}
                               label={'Parroquia'}
                               data={[...dpa_parroquiasES.map(it => it.nombre), '']}
                               setFormValues={setFormValues}
                               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                               formValues={formValues}
                               canEdit={canEdit || (formValues.docx !== '')}
                               handleChange={handleChange} />
                  </Grid>
                  {/*<Grid item xs={12} alignItems="center">*/}
                  {/*  <MyTextField id={'rutaES'}*/}
                  {/*               label={'Ruta'}*/}
                  {/*               handleChange={handleChange}*/}
                  {/*               setFormValues={setFormValues}*/}
                  {/*               formValues={formValues}*/}
                  {/*               canEdit={canEdit}*/}
                  {/*               icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />} />*/}
                  {/*</Grid>*/}
                </> : null
              }

              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Transporte y responsables'} />
              </Grid>
              <Grid container spacing={2} >
                <Grid item xs={6} >
                  <Box sx={{p:'0 1rem 1rem 0.8rem'}}>
                    <MySelect id={'tipoTransporte'}
                              label={'Tipo de Transporte *'}
                              data={['Aéreo', 'Terrestre', 'Fluvial', 'Marítimo']}
                              width={'10rem'}
                              formValues={formValues}
                              setFormValues={setFormValues}
                              icon={<LocalShippingIcon sx={dialog.textFieldIcon} />}
                              canEdit={canEdit || (formValues.docx !== '')} />
                  </Box>
                </Grid>
                <Grid item xs={3} >
                  <Box sx={{p:'0.9rem 1rem 1rem 1rem'}} >
                    <MyDatePicker id={'fechaInicio'}
                                  label={'Fecha Inicio'}
                                  formValues={formValues}
                                  setFormValues={setFormValues} />
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{p:'1rem 1rem 1rem 1rem'}}>
                  <Box sx={{p:'0.9rem 1rem 1rem 1rem'}} >
                    <MyDatePicker id={'fechaFinal'}
                                  label={'Fecha Final'}
                                  formValues={formValues}
                                  setFormValues={setFormValues} />
                  </Box>
                </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6} >
                    {
                      (dInicio < dResolucion) ? <MyTypography>La fecha de inicio no puede ser anterior a la fecha de resolución</MyTypography>:null
                    }
                    {
                      (dInicio > dFinal) ? <MyTypography>Fecha de inicio no puede ser posterior a la fecha final</MyTypography>:null
                    }
                    {
                      (formValues.fechaInicio === formValues.fechaFinal) ? <MyTypography>Fecha final debe ser posterior a la fecha de inicio</MyTypography>:null
                    }
                    {
                      (dFinal > dPlazo) ? <MyTypography>Fecha final no puede ser posterior a la fecha de finalización de vigencia</MyTypography> : null
                    }
                    {
                      (dInicio < dToday) ? <MyTypography>Fecha de inicio no puede ser anterior a la fecha actual</MyTypography> : null
                    }
                  </Grid>
              </Grid>
              <Grid item xs={12} alignItems="center">
                <MyTextField id={'ruta'}
                             label={'Ruta'}
                             handleChange={handleChange}
                             setFormValues={setFormValues}
                             formValues={formValues}
                             canEdit={canEdit || (formValues.docx !== '')}
                             icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />} />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{fontSize:'0.9rem', fontFamily: RobotoCondensedRegular }}>
                  Responsables de la Recolección / transporte *
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MyTableRecolectores id={'responsablesRecoleccion'}
                                     formValues={formValues}
                                     setFormValues={setFormValues}
                                     icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}} />}
                                     canEdit={canEdit || (formValues.docx !== '')} />
              </Grid>
              {
                ((formValues.responsablesRecoleccion?.filter(it => it.transporta === true)?.length === 0) && (formValues.responsableTransportacion === ''))?
                  <MyTypography sx={{ml: '2rem'}}>
                    Al menos una persona debería <strong>transportar</strong> o llene la sección <strong>Responsable de la Transportación</strong>.
                  </MyTypography> : null
              }
              {
                (formValues.responsablesRecoleccion?.filter(it => it.recolecta === true)?.length === 0)?
                  <MyTypography sx={{ml: '2rem'}}>
                     Al menos una persona debería <strong>recolectar</strong>.
                   </MyTypography>:null
              }
              {
                (formValues.responsablesRecoleccion?.filter(it => it.transporta === true)?.length === 0)?
                  <>
                    <Grid item xs={12} sx={{mt:'2rem'}}>
                      <MyTextField id={'responsableTransportacion'}
                                   label={'Responsable de la Transportación *'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                                   canEdit={canEdit || (formValues.docx !== '')}
                                   handleChange={handleChange} />
                    </Grid>
                    <Grid item xs={4}>
                      <MyTextField id={'responsableCedula'}
                                   label={'Cédula'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                                   canEdit={canEdit  || (formValues.docx !== '')}
                                   handleChange={handleChangePhone} />
                    </Grid>
                    <Grid item xs={4}>
                      <MyTextField id={'responsableContacto'}
                                   label={'Número de Contacto'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                                   canEdit={canEdit}
                                   handleChange={handleChangePhone} />
                    </Grid>
                    <Grid item xs={4}>
                      <MyTextField id={'responsableCorreo'}
                                   label={'Correo Electrónico'}
                                   error={emailError}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                                   canEdit={canEdit || (formValues.docx !== '')}
                                   handleChange={handleChange} />
                    </Grid>
                  </>:null
              }
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Declaración de veracidad de la información'} />
              </Grid>
              <Grid item xs={12}>
                <TextField id='texto'
                           multiline
                           rows={10}
                           value={formValues.texto}
                           fullWidth
                           variant='standard'
                           aria-readonly={true}
                           sx={dialog.textTypography}
                           InputProps={{
                             disableUnderline: true,
                             startAdornment:(
                               <InputAdornment position="start">
                                 <AccountCircle sx={{fontSize: '14px', color:'silver'}}/>
                               </InputAdornment>
                             ),
                             sx: {
                               fontSize: '12px',
                               backgroundColor: 'transparent',
                             }
                           }}
                           InputLabelProps={{
                             sx: {
                               fontSize: '14px',
                             }
                           }} />
              </Grid>
              <Grid item xs={12}>
                <MySwitch id={'si'}
                          label={'Aceptar y enviar'}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={handleChange}
                          fullWidth={false}
                          canEdit={canEdit || (formValues.docx !== '')} />
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={7} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'DMR'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            const newFormValues = {...formValues, fecha:today, serial:serial}
                                            getOnSave(newFormValues)()
                                            setFormValues(newFormValues)
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar y emitir declaración'}
                                      width={'18rem'}
                                      onClick={() => {
                                        const doc = MyTagged2Docx(plantilla(formValues), 'Senescyt', mapping({
                                          solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                          payload: {
                                            ...payload,
                                            Propuesta: sa.Propuesta,
                                            Solicitante: sa.Solicitante,
                                            payloadPC
                                          },
                                          autorizador,
                                          coordinador,
                                          perfilUsuario,
                                          nombreOrganizacion,
                                          autoridadSenescyt
                                        }))
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion,
                                        })
                                      }}
                                      disabled={formValues.si === false ||
                                        (formValues.tipoTransporte === '')  ||
                                        (formValues.responsablesRecoleccion?.filter(it => it.transporta === true)?.length === 0 && formValues.responsableTransportacion === '') ||
                                        (formValues.responsablesRecoleccion?.filter(it => it.recolecta === true)?.length === 0) ||
                                        dInicio < dResolucion || dFinal > dPlazo || dPlazo < dInicio || !evCantidadesES || !evCantidadesIS}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {/*{ // TODO: Ocultar*/}
                  {/*  formValues.docx !== '' &&*/}
                  {/*  <ButtonGroup variant={'contained'}>*/}
                  {/*    <MyButtonBacan3 label={'DOCX'}*/}
                  {/*                    icon={FileDownloadIcon}*/}
                  {/*                    onClick={() => API.fetchDownloadDOCX({*/}
                  {/*                      solicitudId: instanciaProceso?.solicitud?.id,*/}
                  {/*                      subfolder,*/}
                  {/*                      filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,*/}
                  {/*                    })}*/}
                  {/*                    width={'5rem'} />*/}
                  {/*    <MyButtonBacan3 label={'PDF'}*/}
                  {/*                    icon={FileDownloadIcon}*/}
                  {/*                    onClick={() => API.fetchDownloadPDF({*/}
                  {/*                      solicitudId: instanciaProceso?.solicitud?.id,*/}
                  {/*                      subfolder,*/}
                  {/*                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf*/}
                  {/*                    })}*/}
                  {/*                    width={'5rem'} />*/}
                  {/*  </ButtonGroup>*/}
                  {/*}*/}
                  {/*{*/}
                  {/*  formValues.docx !== '' &&*/}
                  {/*  <ButtonGroup variant={'contained'}>*/}
                  {/*    <MyFileUploadButton label={'DOCX'}*/}
                  {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                  {/*                        subfolder={subfolder}*/}
                  {/*                        fileName={filenameDOCXuploaded}*/}
                  {/*                        afterUpload={() => {*/}
                  {/*                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {*/}
                  {/*                            if(f.isValid(result2.rutaDocumento)) {*/}
                  {/*                              const arr2 = result2.rutaDocumento.split('/')*/}
                  {/*                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})*/}
                  {/*                            } else {*/}
                  {/*                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})*/}
                  {/*                            }*/}
                  {/*                          })*/}
                  {/*                        }}*/}
                  {/*                        width={'5rem'} />*/}
                  {/*  </ButtonGroup>*/}
                  {/*}*/}
                  {/*{*/}
                  {/*  formValues.docx !== '' &&*/}
                  {/*  <ButtonGroup variant={'contained'}>*/}
                  {/*    <MyFileUploadButton label={'PDF firmado'}*/}
                  {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                  {/*                        subfolder={subfolder}*/}
                  {/*                        fileName={filenamePDFsigned}*/}
                  {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                  {/*                        width={'9rem'} />*/}
                  {/*    {*/}
                  {/*      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                  {/*                                                     icon={FileDownloadIcon}*/}
                  {/*                                                     onClick={() => API.fetchDownloadPDF({*/}
                  {/*                                                       solicitudId: instanciaProceso?.solicitud?.id,*/}
                  {/*                                                       subfolder,*/}
                  {/*                                                       filename: filenamePDFsigned,*/}
                  {/*                                                     })}*/}
                  {/*                                                     width={'3rem'}/>*/}
                  {/*    }*/}
                  {/*  </ButtonGroup>*/}
                  {/*}*/}
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={slct.payload.Solicitud.solicitudAprobada} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              {
                (canEdit)?
                  <>
                    <Grid item xs={12} >
                      <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else
                                           alert('Debe GUARDAR los cambios realizados')
                                       }} />
                        <MyButtonBacan label={'Guardar'} onClick={getOnSave()}
                                       disabled={counter <= 0}
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={(counter > 0) || formValues.docx === '' || !((formValues.tipo === 'IN-SITU')?allInSitu:allExSitu)}
                                      label={'enviar'}
                                      onSend={ () => {
                                        let muestras = [...payloadPC['InformeTecnicoMaate']['muestras']]
                                        payload.Solicitud.recursosIS.forEach(recurso => {
                                          // const cantidadSolicitada = recurso.cantidadSolicitada
                                          const muestraid = JSON.parse(recurso.mid).muestraid
                                          muestras.filter(it => it.id === muestraid).forEach(it => {
                                            it.saldoDRM = it.saldoDRM - recurso.cantidadSolicitada
                                          })
                                        })
                                        payloadPC['InformeTecnicoMaate'].muestras = muestras
                                        payloadPC?.InformeTecnicoMaate?.centrosDocumentacion.forEach(it => {
                                          let recursosES = payload.Solicitud.recursosES.filter(it2 => it2.centroDocumentacion === it.centroDocumentacion)
                                          recursosES = recursosES.map(it2 => ({...it2, mid:JSON.parse(it2.mid)}))
                                          recursosES.forEach(it2 => {
                                            const dato =  {cantidadSolicitada: it2.cantidadSolicitada, id: it2.mid.recursoid}
                                            const row = it.recursos?.filter(it => it.id === dato.id)[0]
                                            row.saldoDRM = row.saldoDRM - dato.cantidadSolicitada
                                          })
                                        })
                                        const metadata = JSON.stringify({"solicitudId": `${instanciaProceso?.solicitud?.id}`,})
                                        API.fetchGetPayload(padreId).then((result) => {
                                          const real0102 = {...result, payload: JSON.parse(result.payload)}
                                          real0102.payload['InformeTecnicoMaate'] = payloadPC['InformeTecnicoMaate']
                                          API.fetchSetPayload(padreId, real0102.payload).then(() => {
                                            dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                          })
                                        })

                                      }} />
                      </Stack>
                    </Grid>
                  </>
                  :
                  <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                    <MyButtonBacan label={'Regresar'}
                                   icon={ArrowBackIcon}
                                   onClick={() => dispatch(handleClear())} />
                  </Grid>
              }
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}

const plantillaIS = (formValues) => {
  const str = '${SALTO}${TITULO2}MATERIAL BIOLÓGICO A MOVILIZAR [recursos in-situ]'+
    '${SALTO}${RECURSOSDRM_IS}' + JSON.stringify(formValues.recursosIS) +
    '${SALTO}${TITULO2}UBICACIÓN | ORIGEN' +
    "${SALTO}${VAR}Provincias${:}" + formValues.provincias?.join(', ') +
    "${SALTO}${VAR}Áreas protegidas${:}" + formValues.areasProtegidas?.join(', ') +
    "${SALTO}${VAR}Bosques protectores${:}" + formValues.bosquesProtectores?.join(', ') +
    '${SALTO}${TITULO2}UBICACIÓN | DESTINO' +
    '${SALTO}${VAR}Provincia${:}' + formValues.provinciaIS +
    '${SALTO}${VAR}Cantón${:}' + formValues.cantonIS +
    '${SALTO}${VAR}Parroquia${:}' + formValues.parroquiaIS +
    '${SALTO}${VAR}Institución en la que se desarrolla la fase de laboratorio${:}' + formValues.laboratoriosIS
  return str
}

const plantillaES = (formValues) => {
  const str = '${SALTO}${TITULO2}MATERIAL BIOLÓGICO A MOVILIZAR [recursos ex-situ]'+
    '${SALTO}${RECURSOSDRM_ES}' + JSON.stringify(formValues.recursosES) +
    '${SALTO}${TITULO2}UBICACIÓN | ORIGEN' +
    '${SALTO}${VAR}Centros de documentación${:}' + formValues.origenES +
    '${SALTO}${VAR}Provincia${:}' + formValues.provinciaCD +
    '${SALTO}${VAR}Cantón${:}' + formValues.cantonCD +
    '${SALTO}${VAR}Parroquia${:}' + formValues.parroquiaCD +
    '${SALTO}${TITULO2}UBICACIÓN | DESTINO' +
    '${SALTO}${VAR}Provincia${:}' + formValues.provinciaES +
    '${SALTO}${VAR}Cantón${:}' + formValues.cantonES +
    '${SALTO}${VAR}Parroquia${:}' + formValues.parroquiaES +
    '${SALTO}${VAR}Institución en la que se desarrolla la fase de laboratorio${:}' + formValues.laboratoriosES
  return str
}

const plantilla = (formValues) => {
  // const respTransportacion = formValues.responsablesRecoleccion
  let respTransportacion = ''
  let respCedula = ''
  if(formValues.responsablesRecoleccion?.filter(it => it.transporta === true)?.length > 0) {
    const resp = formValues.responsablesRecoleccion?.filter(it => it.transporta === true)[0]
    respTransportacion = resp.nombre
    respCedula = resp.cedula
  } else {
    respTransportacion = formValues.responsableTransportacion
    respCedula = formValues.responsableCedula
  }

  const qrInfo =  `ID:${formValues.serial},INICIA:${formValues.fechaInicio},FINALIZA:${formValues.fechaFinal},TRANSPORTISTA:${respTransportacion},CEDULA:${respCedula}`
  let str =
    "${TITULO1}AUTORIZACION DE MOVILIZACIÓN DE ESPECÍMENES DE ESPECIES DE LA DIVERSIDAD BIOLÓGICA" +
    "${SALTO}${QR}SOLICITANTE:<<solicitante.nombres>>,CEDULA:<<solicitante.cedula>>," + qrInfo +
    "${SALTO}${VAR}Código${:}<<serial>>" +
    "${SALTO}${VAR}Fecha de movilización${:}<<sistema.fecha>>" +
    "${SALTO}${TITULO2}DATOS DEL SOLICITANTE" +
    "${SALTO}${VAR}N. Identificación${:}<<solicitante.cedula>>" +
    "${SALTO}${VAR}Nombres${:}<<solicitante.nombres>>" +
    "${SALTO}${TITULO2}DATOS DE LA AUTORIZACIÓN" +
    "${SALTO}${VAR}N. Identificación${:}<<autorizacion.identificador>>" +
    "${SALTO}${VAR}Nombre del proyecto${:}<<nombreProyecto>>" +
    "${SALTO}${VAR}Vigencia${:}<<plazoProyecto>>" +
    "${SALTO}${VAR}Nombre de la Institución Nacional de apoyo${:}<<patrocinador>>" +
    "${SALTO}${PARRAFO.VACIO}" +
    "${SALTO}${TITULO2}DATOS DE LOS RESPONSABLES DE LAS MUESTRAS O ESPECÍMENES A RECOLECTAR / MOVILIZAR" +
    "${SALTO}${PARRAFO.VACIO}" +
    "${SALTO}${VAR2}Tipo de transporte${:}" + formValues.tipoTransporte +
    "${SALTO}${VAR2}Fecha de inicio${:}" + formValues.fechaInicio +
    "${SALTO}${VAR2}Fecha final${:}" + formValues.fechaFinal +
    "${SALTO}${VAR2}Ruta${:}" + formValues.ruta +
    "${SALTO}${PARRAFO.VACIO}" +
    "${SALTO}${WF07.RESPONSABLES}<<wf07.responsables>>" +
    "${SALTO}${PARRAFO.VACIO}"

    // (formValues.responsablesRecoleccion?.filter(it => it.transporta === true)?.length === 0

  if(formValues.responsablesRecoleccion?.filter(it => it.transporta === true)?.length === 0)
    str += "${SALTO}${TITULO2}DATOS DEL RESPONSABLE DE TRANSPORTAR" +
    "${SALTO}${VAR2}Nombre${:}" + formValues.responsableTransportacion +
    "${SALTO}${VAR2}Cédula${:}" + formValues.responsableCedula +
    "${SALTO}${VAR2}Número de contacto${:}" + formValues.responsableContacto +
    "${SALTO}${VAR2}Correo electrónico${:}" + formValues.responsableCorreo +
    "${SALTO}${PARRAFO.VACIO}" +
    "${SALTO}${PARRAFO.VACIO}"

  if(['IN-SITU'].includes(formValues.tipo)) {
    str += plantillaIS(formValues)
  }
  if(['EX-SITU'].includes(formValues.tipo)) {
    str += plantillaES(formValues)
  }
  if(['IN-EX-SITU'].includes(formValues.tipo)) {
    str += plantillaIS(formValues) + plantillaES(formValues)
  }

  return str
}

const mapping = (vars) => {
  const { solicitudId, payload, autorizador, coordinador, perfilUsuario } = vars
  const responsables = payload?.Solicitud?.responsablesRecoleccion?.filter(it => {
    return (it.transporta || it.recolecta)
  })
  const autoridadSenescyt = vars.autoridadSenescyt
  let recs = payload?.Solicitud?.recursos
  let acc = []
  if(payload?.AccesoConocimiento?.aConocimientoTradicional) acc.push('Acceso a conocimiento tradicional.')
  if(payload?.AccesoConocimiento?.clpi !== "") acc.push('CLPI: ' + payload?.AccesoConocimiento?.clpi)
  if(payload?.AccesoConocimiento?.contratoAccesoConocimiento !== "") acc.push('Contrato de acceso a conocimiento: '+ payload?.AccesoConocimiento?.contratoAccesoConocimiento)
  if(payload?.AccesoConocimiento?.ambitoComunitario) acc.push('Ambito comunitario.')
  if(payload?.AccesoConocimiento?.derivadosOSimilares) acc.push('Derivados o similares.')
  if(payload?.AccesoConocimiento?.etnozoologicos) acc.push('Etnozoologicos.')
  if(payload?.AccesoConocimiento?.medicinaTradicional) acc.push('Medicina tradeicional.')
  if(payload?.AccesoConocimiento?.practicasAncestrales) acc.push('Prácticas ancestrales.')
  if(payload?.AccesoConocimiento?.recursosBiologico) acc.push('Recursos biológicos.')
  if(payload?.AccesoConocimiento?.valoresReligiososCultutrales) acc.push('Valores religiosos culturales.')
  return {
    '<<sistema.identificadorInformeTecnicoMaate>>':solicitudId,
    '<<autorizacion.vigencia>>': payload?.Propuesta?.plazo,
    '<<solicitante.identificador>>': solicitudId,
    '<<solicitante.idAcreditacion>>': payload?.Solicitante?.registro,
    '<<solicitante.cedula>>':payload?.Solicitante?.cedula,
    '<<solicitante.nombres>>':payload?.Solicitante?.nombresCompletos,
    '<<institucionApoyo.razonSocial>>': payload?.Propuesta?.apoyo,
    '<<solicitud.areaInvestigacion>>': payload?.Propuesta?.areaInvestigacion,
    '<<recursos.listaRecursos>>': recs?.join(', '),
    '<<ubicacion.listaProvincias>>': payload?.RecursosInSitu?.provincias?.join(', '),
    '<<ubicacion.listaAreasProtegidas>>': payload?.RecursosInSitu?.areasProtegidas?.join(', '),
    '<<ubicacion.listaBosquesProtectores>>': payload?.RecursosInSitu?.bosquesProtectores?.join(', '),
    '<<solicitud.accesoConocimientoTradicional>>': acc?.join(' '),
    '<<plazoProyecto>>': payload?.Propuesta?.plazo + ' meses',
    '<<sistema.identificadorDictamenTecnicoMaate>>':solicitudId,
    '<<sistema.identificadorIDictamenTecnicoSenadi>>':solicitudId,
    '<<sistema.identificadorInformeTecnicoSenescyt>>':solicitudId,
    '<<sistema.identificadorResolucionSenescyt>>':solicitudId,
    '<<sistema.fechaTexto>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<sistema.fecha>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<solicitud.identificador>>':solicitudId,
    '<<solicitante.nombre>>':payload?.Solicitante?.nombresCompletos,
    '<<solicitud.nombreProyecto>>':payload?.Propuesta?.nombre,
    '<<solicitud.plazoProyecto>>':payload?.Propuesta?.plazo,
    '<<patrocinador>>':payload?.Propuesta?.apoyo,
    '<<sistema.nombreAutorizadorPrincipalSenescyt>>':autorizador?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorPrincipalSenescyt>>':autorizador?.nombrePerfil ?? '',
    '<<sistema.cargoAutorizadorPrincipalSenescyt>>':autorizador?.descripcionPerfil ?? '',
    '<<sistema.nombreAutorizadorSectorialMaate>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorSectorialMaate>>':coordinador?.nombrePerfil ?? '',
    '<<usuario.cargoAutorizadorSectorialMaate>>':coordinador?.descripcionPerfil ?? '',
    '<<sistema.nombreCoordinadorCasosSenescyt>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolCoordinadorCasosSenescyt>>':coordinador?.nombrePerfil ?? '',
    '<<sistema.cargoCoordinadorCasosSenescyt>>':coordinador?.descripcionPerfil  ?? '',
    '<<sistema.telefono>>':coordinador?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<sistema.correoElectronico>>':coordinador?.correoUsuario ?? '',
    '<<usuario.nombre>>':perfilUsuario?.usuario?.nombreUsuario ?? '',
    '<<usuario.rol>>':perfilUsuario?.perfil?.nombrePerfil ?? '',
    '<<usuario.cargo>>':perfilUsuario?.perfil?.descripcionPerfil ?? '',
    '<<usuario.telefono>>':perfilUsuario?.usuario?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<usuario.correoElectronico>>':perfilUsuario?.usuario?.correoUsuario ?? '',
    '<<sistema.nombreAutoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<sistema.rolAutoridadSenescyt>>': autoridadSenescyt?.nombrePerfil ?? '',
    '<<sistema.cargoAutoridadSenescyt>>': autoridadSenescyt?.descripcionPerfil ?? '',
    '<<sistema.autoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<wf07.provincias>>': payload?.Solicitud?.provincias?.join(', '),
    '<<wf07.areasProtegidas>>': payload?.Solicitud?.areasProtegidas?.join(', '),
    '<<wf07.laboratorio>>': payload?.Solicitud?.destinoFinal,
    '<<wf07.responsables>>': JSON.stringify(responsables),
    '<<wf07.recursos>>': JSON.stringify(payload?.Solicitud?.recursos),
    '<<solicitud.responsable.nombre>>': payload?.Solicitud?.responsableTransportacion,
    '<<solicitud.responsable.pasaporte>>': payload?.Solicitud?.responsableCedula,
    '<<nombreProyecto>>': payload?.Propuesta?.nombre,
    '<<wf07.recursosDRM>>': JSON.stringify(recs),
    '<<serial>>': payload?.Solicitud?.serial,
    '<<autorizacion.identificador>>': payload.payloadPC?.ElaborarContrato?.serial ?? payload.payloadPC?.ElaborarPermiso?.serial ?? '',
  }
}
