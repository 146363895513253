// import React from 'react'

export const ENV = 'INSTITUCIONAL'
// export const ENV = 'PRUEBAS'

export const NewGLOBALS = () => {
  if(ENV === 'PRUEBAS') {
    return {
      // TODO: restituir
      // mainUrl : 'https://testvuv.tech/ws',
      // investigadoresWS : 'https://testvuv.tech/iv/api/v1/vuv/investigador'

      // TODO: TEMPORAL
      mainUrl : 'https://vuvib-senescyt.net/ws',
      investigadoresWS : 'https://testvuv.tech/iv/api/v1/vuv/investigador'
    }
  }
  if(ENV === 'INSTITUCIONAL') {
    return {
      mainUrl : 'https://vuvib-senescyt.net/ws',
      investigadoresWS : 'https://testvuv.tech/iv/api/v1/vuv/investigador'
    }
  }
}

const GLOBALS = {
  mainUrl : NewGLOBALS().mainUrl,
  investigadoresWS : NewGLOBALS().investigadoresWS,
  year: 0,
}

export default GLOBALS
