import {styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const MyTypography = styled(Typography)({
  color: 'black',
  fontSize: '12px',
  borderBottom: '1px dotted blue',
  background: 'rgba(255,0,0,0.2)',
  padding: '8px'
});
